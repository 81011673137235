const namespace = 'booking'
import { useUserStore } from "@/stores/user";
import { useBookingsStore } from "@/stores/bookings";

export default ($request) => ({
  getOwnBookings() {
    return $request.get(`${namespace}s/own`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        if (resp.code && resp.code == 404){
          return []
        }
        return resp
      })
  },
  getBookingsByYear(year) {
    return $request.get(`${namespace}s/list/${year}`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },
  getBookingById(id) {
    return $request.get(`${namespace}/${id}`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },
  postBooking(reason, start, end, leave_type, user_id) {
    const userStore = useUserStore()
    const data = {
      "reason": reason,
      "start_date": (start/1000).toString(),
      "end_date": (end/1000).toString(),
      "leave_type": leave_type,
      "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    if (user_id) {
      data.user_id = user_id
    } else {
      data.user_id = userStore.theAccount.id
    }


    return $request.post(`${namespace}`, data)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        console.log(resp);
        return resp
      })
  },
  putBooking(id, reason, start, end, leave_type, user_id) {
    const userStore = useUserStore()
    const data = {
      "reason": reason,
      "start_date": (start/1000).toString(),
      "end_date": (end/1000).toString(),
      "leave_type": leave_type,
      "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    if (user_id) {
      data.user_id = user_id
    } else {
      data.user_id = userStore.theAccount.id
    }

    return $request.put(`${namespace}/${id}`, data)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },
  deleteBooking(id) {
    return $request.delete(`${namespace}/${id}`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },
})
