export const isDayPartSelected = (day, part, selectedRange) => {
  if (
    day !== null &&
    selectedRange.startDate !== null &&
    selectedRange.endDate !== null
  ) {
    const formattedDate = new Date(
      day.getFullYear(),
      day.getMonth(),
      day.getDate(),
      formatTimeByDayPart(part),
      0,
      0
    ).getTime();
    const startDate = new Date(
      selectedRange.startDate.getFullYear(),
      selectedRange.startDate.getMonth(),
      selectedRange.startDate.getDate(),
      formatTimeByDayPart(selectedRange.startTime),
      0,
      0
    ).getTime();
    const endDate = new Date(
      selectedRange.endDate.getFullYear(),
      selectedRange.endDate.getMonth(),
      selectedRange.endDate.getDate(),
      formatTimeByDayPart(selectedRange.endTime),
      0,
      0
    ).getTime()
    return formattedDate >= startDate && formattedDate <= endDate;
  }
  return false;
}

export const formatTimeByDayPart = (part) => {
  if (part === 0){
    return 8
  }
  if (part === 1){
    return 16
  }
  if (part === "Reggel"){
    return 8
  }
  if (part === "Este"){
    return 16
  }
  if (part === "Ebédidő"){
    return 12
  }
}

export const equalDates = (date1, date2) => {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
}
