const namespace = 'working-hour'

export default ($request) => ({

  getWorkingHours() {
    return $request.get(`${namespace}s/list`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  getWorkingHourById(id) {
    return $request.get(`${namespace}/${id}`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  postWorkingHour(start_date, end_date, user_id) {
    const data = {
      "start_date": start_date.getTime()/1000,
      "end_date": end_date.getTime()/1000,
      "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
      "user_id": user_id
    }


    return $request.post(`${namespace}`, data)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return;
        }
        return resp;
      })
  },

  putWorkingHour(id, start_date, end_date, user_id) {
    const data = {
      "start_date": start_date.getTime()/1000,
      "end_date": end_date.getTime()/1000,
      "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
      "user_id": user_id
    }

    return $request.put(`${namespace}/${id}`, data)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  deleteWorkingHour(id) {
    return $request.delete(`${namespace}/${id}`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  }

})
