const namespace = 'user'

export default ($request) => ({

  me() {
    return $request.get(`${namespace}/me`)
  },

  getUsers() {
    return $request.get(`${namespace}s`).then(resp => {
      if (typeof resp == 'undefined') {
        return
      }
      return resp
    })
  },

  postUser(data) {
    return $request.post(`${namespace}`, data).then(resp => {
      if (typeof resp == 'undefined') {
        return
      }
      return resp.payload
    })
  },

  updateUser(data, id) {
    return $request.put(`${namespace}/${id}`, data).then(resp => {
      if (typeof resp == 'undefined') {
        return
      }
      return resp.payload
    })
  }

})

