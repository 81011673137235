<template>
  <div class="month-selector">

    <div class="calendar-selector">
      <div
        class="calendar-prev"
        @click="prevMonth()"
      >
        <i class="icon arrow-left" />
      </div>

      <div
        class="calendar-next"
        @click="nextMonth()"
      >
        <i class="icon arrow-right" />
      </div>
    </div>
    <span class="calendar-text">
      <div class="range-start">{{ currentYear }}. {{ monthNames[currentMonth]  }}</div>
      <div v-if="showRangeEnd">-</div>
      <div class="range-end" v-if="showRangeEnd">{{ rangeEndYear }}. {{ monthNames[rangeEndMonth] }}</div>
    </span>

  </div>
</template>

<script setup>
import { ref } from 'vue';
const emit = defineEmits(['update:modelValue']);
const minYear = parseInt(process.env.VUE_APP_MIN_YEAR);
const maxYear = new Date().getFullYear();

const showRangeEnd = ref(true);
const currentYear = ref(new Date().getFullYear());
const currentMonth = ref(new Date().getMonth());
const rangeEndYear = ref(new Date(currentYear.value, currentMonth.value + 1).getFullYear());
const rangeEndMonth = ref(new Date(currentYear.value, currentMonth.value + 1).getMonth());

const prevMonth = () => {
  showRangeEnd.value = false;
  if (currentMonth.value === 0) {
    if (currentYear.value > minYear) {
      currentMonth.value = 11;
      currentYear.value--;
    }
  } else {
    currentMonth.value--;
  }
  emit('update:modelValue', { year: currentYear.value, month: currentMonth.value });
};

const nextMonth = () => {
  showRangeEnd.value = false;
  if (currentMonth.value === 11) {
    if (currentYear.value < maxYear) {
      currentMonth.value = 0;
      currentYear.value++;
    }
  } else {
    currentMonth.value++;
  }
  emit('update:modelValue', { year: currentYear.value, month: currentMonth.value });
};

const monthNames = [
  'Január',
  'Február',
  'Március',
  'Április',
  'Május',
  'Június',
  'Július',
  'Augusztus',
  'Szeptember',
  'Október',
  'November',
  'December',
];
</script>
