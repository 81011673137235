<template>
  <div
    name="woking-hours-modal"
    class="modal working-hours-modal"
  >
    <div
      class="modal-mask"
    >
      <div
        class="modal-container"
        ref="modalContainer"
      >

        <div class="modal-header">
          {{ title }}
          <i
            class="icon close"
            @click="closeModal"
          />
        </div>

        <div class="modal-body">

          <div class="row">
            {{ props.userId }}
          </div>

          <div class="row">
            <div class="select-group">
              <div class="title">Kezdődátum</div>
              <div class="selects">
                <div class="day-picker">
                  <Datepicker
                    v-model="selectedStartDate"
                    :locale="hu"
                  />
                </div>

                <DropdownSelect
                  v-if="!rangeMode"
                  v-model="selectedStartTime"
                  class="time"
                  :options="availableStartTimeOptions"
                  :default-selected-option="selectedStartTime"
                />
              </div>
            </div>
            <div class="select-group">
              <div class="title">Végdátum</div>
              <div class="selects">
                <div class="day-picker">
                  <Datepicker
                    v-model="selectedEndDate"
                    :lowerLimit="selectedStartDate"
                    :locale="hu"
                  />
                </div>
                <DropdownSelect
                  v-if="!rangeMode"
                  v-model="selectedEndTime"
                  class="time"
                  :options="availableEndTimeOptions"
                  :default-selected-option="selectedEndTime"
                />
              </div>
            </div>
          </div>

        </div>
        <div
          v-if="!existingWorkingHour"
          class="modal-footer"
        >
          <button
            v-if="canModify"
            class="button"
            @click="send"
          >
          <template v-if="!isLoading">
            <i class="icon tick"/>
            Munkaidő elküldése
          </template>
          <template v-else>
            <i class="icon loader white"/>
          </template>

          </button>
          <div
            v-else
            class="text"
          >
            Már nem módosítható a foglalás
          </div>
        </div>
        <div
          v-else
          class="modal-footer"
        >
          <button class="button" @click="modifyWorkingHour">
            <template v-if="!isLoading">Munkaóra módosítása</template>
            <template v-else><i class="icon icon loader white"/></template>
          </button>
          <button class="button" @click="deleteWorkingHour">
            <template v-if="!isLoading">Munkaóra törlése</template>
            <template v-else><i class="icon icon loader white"/></template>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DropdownSelect from './DropdownSelect.vue';
import Datepicker from 'vue3-datepicker'
import { toast } from 'vue3-toastify';
import { apiService } from '@/composables/useApiService';
import {ref, onMounted, watch, computed } from 'vue';
import { hu } from 'date-fns/locale';
import { equalDates } from '@/utils/date';
import { useUserStore } from '@/stores/user';
import {useWorkingHoursStore} from '@/stores/working-hours';
import { useHolidayStore } from '@/stores/holidays';


const emit = defineEmits(['close']);
const workingHoursStore = useWorkingHoursStore();
const userStore = useUserStore();
const holidayStore = useHolidayStore();

const startTimeOptions = ['Reggel', 'Ebédidő'];
const endTimeOptions = ['Ebédidő', 'Este'];

const isLoading = ref(false);

const modalContainer = ref(null);
const props = defineProps({
  startDate: {
    type: Date,
    default: new Date(),
  },
  endDate: {
    type: Date,
    default: new Date(),
  },
  startTime: {
    type: Number,
    default: 0,
  },
  endTime: {
    type: Number,
    default: 1,
  },
  userId: {
    type: String,
    default: null,
  },
  existingWorkingHour: {
    type: Object,
    default: null,
  },
});


const selectedStartDate = ref(props.startDate);
const selectedEndDate = ref(props.endDate ? props.endDate: props.startDate);
const selectedStartTime = ref(startTimeOptions[props.startTime]);
const selectedEndTime = ref(endTimeOptions[props.endTime]);

const rangeMode = computed(() => {
  return !equalDates(selectedStartDate.value, selectedEndDate.value);
});

const title = computed(() => {
  if (props.existingWorkingHour) {
    return 'Foglalás módosítása';
  }
  return 'Munkaidő foglalása';
});

const availableStartTimeOptions = computed(() => {
  if (selectedEndTime.value == endTimeOptions[0] && equalDates(selectedStartDate.value, selectedEndDate.value)) {
    return [startTimeOptions[0]];
  } else {
    return startTimeOptions;
  }
});
const availableEndTimeOptions = computed(() => {
  if (selectedStartTime.value == startTimeOptions[1] && equalDates(selectedStartDate.value, selectedEndDate.value)) {
    return [endTimeOptions[1]];
  } else {
    return endTimeOptions;
  }
});



watch(() => selectedStartDate.value, (newStartDate) => {
  if (selectedEndDate.value < newStartDate){
    selectedEndDate.value = newStartDate;
  }
});

const getDayPartFromDate = (date, time) => {
  const hours = date.getHours();
  if (hours === 8) {
    return startTimeOptions[0];
  }
  if (hours === 12 && time === "start") {
    return startTimeOptions[1];
  }
  if (hours === 12 && time === "end") {
    return endTimeOptions[0];
  }
  if (hours === 16) {
    return endTimeOptions[1];
  }

};

const isDateNWD = (date) => {
  return date.getDay() === 0 || date.getDay() === 6;
};
const isDateHoliday = (date) => {
  return holidayStore.getHolidayDates.some((holiday) => {
    return equalDates(date, holiday);
  });
};

const closeModal = () => {
  emit('close');
};


const send = () => {
  isLoading.value = true;
  if (!equalDates(selectedStartDate.value, selectedEndDate.value)){
    sendRange();
    return;
  }


  const start = new Date(selectedStartDate.value);
  const end = new Date(selectedEndDate.value);

  if (startTimeOptions.indexOf(selectedStartTime.value) === 0) {
    start.setHours(8, 0, 0);
  }else{
    start.setHours(12, 0, 0);
  }
  if (endTimeOptions.indexOf(selectedEndTime.value) === 0) {
    end.setHours(12, 0, 0);
  }else{
    end.setHours(16, 0, 0);
  }

  const userId = props.userId ? props.userId : userStore.theAccount.id;

  apiService.workingHours.postWorkingHour(start, end, userId).then((resp) => {
    if (resp.code === 400) {
      toast.error('Ez a nap már bejegyezve!');
      return;
    }
    workingHoursStore.addWorkingHour(resp.payload);
    toast.success('Sikeres felvitel!');
  }).catch((error) => {
    console.log(error);
    toast.error('Sikertelen felvitel!');
  }).finally(() => {
    closeModal();
    isLoading.value = false;
  })
};

const sendRange = () => {
  isLoading.value = true;
  const start = new Date(selectedStartDate.value).setHours(8,0,0);
  const end = new Date(selectedEndDate.value).setHours(16,0,0);

  const userId = props.userId ? props.userId : userStore.theAccount.id;

  const currentDate = new Date(start);
  while (currentDate <= end) {
    const newDate = new Date(currentDate);
    const newEndDate = new Date(currentDate);
    newEndDate.setHours(16,0,0);
    if (currentDate.getHours() === 12) {
      newDate.setHours(12,0,0);
      newEndDate.setHours(12,0,0);
    }
    if (!isDateNWD(newDate) && !isDateHoliday(newDate)) {
      apiService.workingHours.postWorkingHour(newDate, newEndDate, userId).then((resp) => {
        workingHoursStore.addWorkingHour(resp.payload);
      }).catch((error) => {
        console.log(error);
        toast.error('Sikertelen felvitel!');
        closeModal();
        return;
      });
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  closeModal();
  toast.success('Sikeres felvitel!');
  isLoading.value = false;
};

const modifyWorkingHour = () => {
  isLoading.value = true;
  const start = new Date(selectedStartDate.value);
  const end = new Date(selectedEndDate.value);

  if (startTimeOptions.indexOf(selectedStartTime.value) === 0) {
    start.setHours(8, 0, 0);
  }else{
    start.setHours(12, 0, 0);
  }
  if (endTimeOptions.indexOf(selectedEndTime.value) === 0) {
    end.setHours(12, 0, 0);
  }else{
    end.setHours(16, 0, 0);
  }

  apiService.workingHours.putWorkingHour(
      props.existingWorkingHour.id,
      start,
      end,
      props.existingWorkingHour.user.id
    ).then((resp) => {
    workingHoursStore.modifyWorkingHour(resp.payload, props.existingWorkingHour.user.id);
    toast.success('Sikeres módosítás!');
  }).catch((error) => {
    console.log(error);
    toast.error('Sikertelen módosítás!');
  }).finally(() => {
    closeModal();
    isLoading.value = false;
  })
}

const deleteWorkingHour = () => {
  isLoading.value = true;
  apiService.workingHours.deleteWorkingHour(props.existingWorkingHour.id).then(() => {
    workingHoursStore.deleteWorkingHour(props.existingWorkingHour.id, props.existingWorkingHour.user.id);
    toast.success('Sikeres törlés!');
  }).catch((error) => {
    console.log(error);
    toast.error('Sikertelen törlés!');
  }).finally(() => {
    closeModal();
    isLoading.value = false;
  })
}

const canModify = computed(() => {
  if (props.existingWorkingHour) {
    const startDate = new Date(props.existingWorkingHour.start_date);
    const endDate = new Date(props.existingWorkingHour.end_date);
    const now = new Date();
    return startDate > now && endDate > now;
  }
  return true;
});



onMounted(() => {
  if (props.existingWorkingHour) {
    selectedStartDate.value = new Date(props.existingWorkingHour.start_date);
    selectedEndDate.value = new Date(props.existingWorkingHour.end_date);
    selectedStartTime.value = getDayPartFromDate(new Date(props.existingWorkingHour.start_date), "start");
    selectedEndTime.value = getDayPartFromDate(new Date(props.existingWorkingHour.end_date), "end");
  }
});
</script>
