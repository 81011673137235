<template>
  <div class="view my-account-view">
    <div v-if="!isPageLoading" class="container">
      <div class="header">
        <div class="title">Fiókom</div>
      </div>
      <UserForm
        :user="user"
        :showCancel="false"
        :disabled="true"
        :isLoading="isLoading"
        @save="save"
      />
    </div>
    <div v-else class="loader-container">
      <i class="icon loader black" />
    </div>
  </div>
</template>

<script setup>
import UserForm from '@/components/UserForm.vue';
import { useUserStore } from '@/stores/user';
import { computed, ref, watchEffect } from 'vue';
import { apiService } from '@/composables/useApiService';
import { toast } from 'vue3-toastify';

const userStore = useUserStore();
const user = computed(() => userStore.theAccount);
const isLoading = ref(false);
const isPageLoading = ref(false);

const save = (data) => {
  isLoading.value = true
  apiService.user.updateUser(data, userStore.theAccount.id).then(()=>{
    isLoading.value = false,
    toast.success('Sikeres mentés')
  }).catch(()=>{
    isLoading.value = false,
    toast.error('Hiba történt a mentés közben')
  })
};

watchEffect(() => {
  if (!userStore.theAccount) {
    isPageLoading.value = true;
  } else {
    isPageLoading.value = false;
  }
});

</script>
