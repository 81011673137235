import { useUserStore } from "@/stores/user"

const namespace = 'auth'

export default ($request) => ({
  // register(name, company, email, phone, password, languageCode, companyWebsite) {
  //   return $request.post(`${namespace}/register`, { name, company, email, phone, password, language: languageCode, company_website: companyWebsite })
  // },

  login(email, password) {
    return $request.post(`${namespace}/login`, { email, password })
      .then(resp => {
        const userStore = useUserStore()
        userStore.login(resp.payload)

        return resp
      })
  },

  logout() {
    return $request.post(`${namespace}/logout`)
      .then(() => {
        useUserStore().logout()
      })
  },

  requestforgottenPassword(email) {
    return $request.post(`${namespace}/forgotten-password`, { email })
  },

  forgottenPasswordValidation(email, password, token) {
    return $request.post(`${namespace}/forgotten-password/${token}`, { email, password })
  }
})
