<template>
  <div class="user-form">
    <div class="group">
      <label>Név*</label>
      <input v-model="lastName" type="text" placeholder="Vezetéknév*" />
      <input v-model="firstName" type="text" placeholder="Keresztnév*" />
    </div>

    <div class="group">
      <label>Email*</label>
      <input v-model="email" type="email" placeholder="Email*" />
    </div>

    <div class="group">
      <label>Telefonszám</label>
      <input v-model="phone" type="text" placeholder="Telefonszám" />
    </div>

    <div class="group">
      <label>Cím*</label>
      <input v-model="addressFirst" type="text" placeholder="Cím*" />
      <input
        v-model="addressSecond"
        type="text"
        placeholder="Cím második sora"
      />
    </div>

    <div class="group">
      <label>Szabadságok száma*</label>
      <input
        v-model="allowance"
        type="text"
        placeholder="Szabadságok száma*"
        :disabled="disabled"
       />
    </div>

    <div class="group">
      <label>Születési dátum*</label>
      <Datepicker v-model="birthDate" />
    </div>

    <div class="group">
      <label>Munkavállalás kezdete*</label>
      <Datepicker
        v-model="startDate"
        :disabled="disabled"
      />
    </div>

    <div
      v-if="!disabled"
      class="group"
    >
      <label>Szerepkör*</label>
      <DropdownSelect
        v-model="userType"
        :options="userTypes"
        label="label"
        :default-selected-option="userType"
        dropdown-placeholder="Szerepkör*"
      />
    </div>

    <div
      v-if="!disabled"
      class="group"
    >
      <label>Foglalkoztatás típusa</label>
      <DropdownSelect
        v-model="employmentType"
        :options="employmentTypes"
        label="label"
        :default-selected-option="employmentType"
        dropdown-placeholder="Foglalkoztatás típusa*"
      />

      <label>Időzóna</label>
      <DropdownSelect
        v-model="timeZone"
        :options="timeZones"
        :default-selected-option="timeZone"
        dropdown-placeholder="Időzóna*"
        :searchable="true"
      />
    </div>


    <div class="form-footer">
      <button
       class="button primary"
        @click="save()"
      >
        <template v-if="!isLoading">Mentés</template>
        <i v-else class="icon loader white"/>
      </button>
      <div
        v-if="showCancel"
        class="button secondary"
        @click="cancel()"
      >
        Mégse
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import DropdownSelect from '@/components/DropdownSelect.vue';
import Datepicker from 'vue3-datepicker'
import { toast } from 'vue3-toastify';
import {isValidEmail} from '@/utils/validators'

const emit = defineEmits(['cancel', 'save']);

const userTypes = [
  { label: 'Admin', value: 'admin' },
  { label: 'Felhasználó', value: 'user' },
  { label: 'Tulajdonos', value: 'owner' },
];

const employmentTypes = [
  { label: 'Teljes munkaidő', value: 'full_time' },
  { label: 'Részmunkaidő (4 óra)', value: 'part_time_4' },
  { label: 'Részmunkaidő (6 óra)', value: 'part_time_6' },
];

const timeZones = Intl.supportedValuesOf('timeZone');


const lastName = ref('');
const firstName = ref('');
const email = ref('');
const phone = ref('');
const addressFirst = ref('');
const addressSecond = ref('');
const allowance = ref('');
const birthDate = ref(null);
const startDate = ref(null);
const userType = ref('');
const employmentType = ref('');
const timeZone = ref(Intl.DateTimeFormat().resolvedOptions().timeZone);

const props = defineProps({
  user: {
    type: Object,
    default: null,
  },
  showCancel: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

watch(
  () => props.user,
  (user) => {
    if (user) {
      lastName.value = user.last_name;
      firstName.value = user.first_name;
      email.value = user.email;
      phone.value = user.phone;
      addressFirst.value = user.address_line;
      addressSecond.value = user.address_line2;
      allowance.value = user.allowance;
      birthDate.value = new Date(props.user.birth_date);
      startDate.value = new Date(props.user.commencement_date);
      userType.value = userTypes.find((type) => type.label === props.user.user_type.name);
      employmentType.value = employmentTypes.find((type) => type.label === props.user.employment_type);
      timeZone.value = props.user.timezone;
    }
  },
);

const save = () => {
  if (!validateForm()) {
    return
  }
  emit('save', {
    last_name: lastName.value,
    first_name: firstName.value,
    email: email.value,
    phone: phone.value,
    address_line: addressFirst.value,
    address_line2: addressSecond.value,
    allowance: allowance.value,
    birth_date: birthDate.value.getTime()/1000,
    commencement_date: startDate.value.getTime()/1000,
    user_type: userType.value.value,
    employment_type: employmentType.value.value,
    timezone: timeZone.value,
  });
};

const cancel = () => {
  emit('cancel');
};

const validateForm = () => {
  if (!lastName.value) {
    toast.error('Vezetéknév megadása kötelező',{toastId: 'lastName'})
    return false
  }
  if (!firstName.value) {
    toast.error('Keresztnév megadása kötelező', {toastId: 'firstName'})
    return false
  }
  if (!email.value) {
    toast.error('Email megadása kötelező', {toastId: 'email'})
    return false
  } else if (!isValidEmail(email.value)) {
    toast.error('Érvénytelen email cím', {toastId: 'email'})
    return false
  }
  if (!addressFirst.value) {
    toast.error('Cím megadása kötelező', {toastId: 'addressFirst'})
    return false
  }
  if (!allowance.value) {
    toast.error('Szabadságok száma megadása kötelező', {toastId: 'allowance'})
    return false
  }
  if (!birthDate.value) {
    toast.error('Születési dátum megadása kötelező', {toastId: 'birthDate'})
    return false
  }
  if (!startDate.value) {
    toast.error('Munkavállalás kezdete megadása kötelező', {toastId: 'startDate'})
    return false
  }
  if (!userType.value) {
    toast.error('Szerepkör megadása kötelező', {toastId: 'userType'})
    return false
  }
  if (!employmentType.value) {
    toast.error('Foglalkoztatás típusa megadása kötelező', {toastId: 'employmentType'})
    return false
  }
  return true
}


onMounted(() => {
  if (props.user) {
    lastName.value = props.user.last_name;
    firstName.value = props.user.first_name;
    email.value = props.user.email;
    phone.value = props.user.phone;
    addressFirst.value = props.user.address_line;
    addressSecond.value = props.user.address_line2;
    allowance.value = props.user.allowance;
    birthDate.value = new Date(props.user.birth_date);
    startDate.value = new Date(props.user.commencement_date);
    userType.value = userTypes.find((type) => type.value === props.user.user_type.name);
    employmentType.value = employmentTypes.find((type) => type.value === props.user.employment_type);
    timeZone.value = props.user.timezone;
  }
});

</script>
