const namespace = 'employment-type'

export default ($request) => ({

  getEmploymentTypes() {
    return $request.get(`${namespace}s`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  getEmploymentTypeById(id) {
    return $request.get(`${namespace}/${id}`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  postEmploymentType(name, machine_name, working_hours) {
    const data = {
      "name": name,
      "machine_name": machine_name,
      "working_hours": working_hours
    }

    return $request.post(`${namespace}`, data)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  putEmploymentType(id, name, machine_name, working_hours) {
    const data = {
      "name": name,
      "machine_name": machine_name,
      "working_hours": working_hours
    }

    return $request.put(`${namespace}/${id}`, data)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  deleteEmploymentType(id) {
    return $request.delete(`${namespace}/${id}`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  }

})
