<template>
  <div
    class="calendar horizontal-month-calendar"
  >
    <div
      v-if="showHeader"
      class="day-names-wrapper"
    >
      <div
        v-for = "(day, index) in calculateDisplayedDays(startingDate)"
        :key="index"
        class="day-names"
      >
      {{ dayNames[day.getDay()] }}
      </div>
    </div>

    <div class="day-grid">
      <div
        v-for="(day, dayIndex) in calculateDisplayedDays(startingDate)"
        :key="dayIndex"
        :class="['day-square', {'nwd': isWeekend(day), 'holiday': isHoliday(day)}]"
        :titleToShow=addHolidayTitle(day)
      >
        <div class="day-number">
          {{  day.getDate() }}
        </div>
        <div
          class="day-part"
          :class="[
            addDayPartClasses(day, 0),
            { selected: isDayPartSelected(day, 0, selectedRange ) }
          ]"
          @click="handleDayPartClick(displayedMonth, day, 0)"
          @mousedown="props.disableRange ? null : handleMouseDown($event, day, 0)"
          @mouseup="props.disableRange ? null : handleMouseUp(day, 0)"
          @mouseover="props.disableRange ? null : handleMouseOver($event, day, 0)"
        >
        </div>
        <div
          class="day-part"
          :class="[
            addDayPartClasses(day, 1),
            { selected: isDayPartSelected(day, 1, selectedRange) }
          ]"
          @click="handleDayPartClick(displayedMonth, day, 1)"
          @mousedown="props.disableRange ? null : handleMouseDown($event, day, 1)"
          @mouseup="props.disableRange ? null : handleMouseUp(day, 1)"
          @mouseover="props.disableRange ? null : handleMouseOver($event, day, 1)"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue';
import { useHolidayStore } from '@/stores/holidays';
import  { isDayPartSelected, formatTimeByDayPart }  from '@/utils/date';

const holidayStore = useHolidayStore();
const holidays = computed(() => holidayStore.getHolidays);

const emit = defineEmits(['update:modelValue', 'dayClicked', 'bookingClicked', 'workingHourClicked', 'rangeSelected']);

const props = defineProps({
  startingDate: {
    type: Date,
    default: null,
  },
  showHeader: {
    type: Boolean,
    default: false,
  },
  bookedDays: {
    type: Array,
    default: () => [],
  },
  workingHours: {
    type: Array,
    default: () => [],
  },
  disableClicks: {
    type: Boolean,
    default: false,
  },
  disableRange: {
    type: Boolean,
    default: false,
  },
});

const dayNames = ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'];
const displayedMonth = ref([]);

const calculateDisplayedDays = (date) => {
  const days = [];
  if (!date) {
    const startingDay = new Date();
    const endingDate = new Date(startingDay.getFullYear(), startingDay.getMonth() + 1, startingDay.getDate());
    const tempStartingDay = new Date(startingDay.getFullYear(), startingDay.getMonth(), startingDay.getDate());
    while (tempStartingDay <= endingDate) {
      days.push(new Date(tempStartingDay.getFullYear(), tempStartingDay.getMonth(), tempStartingDay.getDate()));
      tempStartingDay.setDate(tempStartingDay.getDate() + 1);
    }
  }
  else {
    const startingDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const lastDayOfMonth = new Date(startingDay.getFullYear(), date.getMonth() + 1, 0);

    for (let i = 0; i < lastDayOfMonth.getDate(); i++) {
      days.push(new Date(startingDay.getFullYear(), startingDay.getMonth(), startingDay.getDate() + i));
    }
  }
  return days;
};

const isWeekend = (day) => {
  return day.getDay() === 0 || day.getDay() === 6;
};

const isHoliday = (day) => {
  if (day === null) {
    return false;
  }
  return holidays.value.some((holiday) => {
    const date = new Date(holiday.holiday_date);
    return date.getFullYear() === day.getFullYear()
      && date.getMonth() === day.getMonth()
      && date.getDate() === day.getDate();
  });
};
const addHolidayTitle = (day) => {
  if (day === null) {
    return '';
  }
  if (isHoliday(day)) {
    return holidays.value.find((holiday) => {
      const date = new Date(holiday.holiday_date);
      return date.getFullYear() === day.getFullYear()
        && date.getMonth() === day.getMonth()
        && date.getDate() === day.getDate();
    }).note;
  }
  return '';
};

const handleDayPartClick = (month, day, time) => {
  if (isBookedDay(day, time) || isAlreadyFilled(day, time)) {
    const formattedDate = new Date(day.getFullYear(), day.getMonth(), day.getDate(), formatTimeByDayPart(time), 0, 0).getTime();

    const booking = props.bookedDays.find((booking) => {
      return new Date(booking.start_date).getTime() <= formattedDate
        && new Date(booking.end_date).getTime() >= formattedDate;
    });

    const workingHour = props.workingHours.find((workingHour) => {
      return new Date(workingHour.start_date).getTime() <= formattedDate
        && new Date(workingHour.end_date).getTime() >= formattedDate;
    });
    if (booking) {
      emit('bookingClicked', booking);
    }
    if (workingHour) {
      emit('workingHourClicked', workingHour);
    }
    return;
  }
  if (
      day !== null
      && !isWeekend(day)
      && selectedRange.value.endDate === null
    ) {
    const data = {
      year: day.getFullYear(),
      month: day.getMonth(),
      day: day.getDate(),
      time: time,
    };
    emit('dayClicked', data);
  }
};

const selectedRange = ref({
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
});

const handleMouseDown = (e, day, time) => {
  e.target.classList.add('selected');
  selectedRange.value.startDate = day;
  selectedRange.value.startTime = time;
};

// If the mouse is over a day, and the start date is not null, set the end date to the day
const handleMouseOver = (e, day, time) => {
  if (selectedRange.value.startDate === null) {
    return;
  }
  if (selectedRange.value.startDate ) {
    selectedRange.value.endDate = day;
    selectedRange.value.endTime = time;
  }
};
// If the mouse is up, set the end date to the day
const handleMouseUp = (day, time) => {
  selectedRange.value.endDate = day;
  selectedRange.value.endTime = time;
  emitRangeSelected();

  removeAllSelection();
  selectedRange.value = {
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  };


};

const removeAllSelection = () => {
  const selected = document.querySelectorAll('.selected');
  selected.forEach((element) => {
    element.classList.remove('selected');
  });
};

const emitRangeSelected = () => {
  // If the start date and end date are not null and they are not the same
  if (selectedRange.value.startDate
    && selectedRange.value.endDate
    && (selectedRange.value.startDate.getTime() != selectedRange.value.endDate.getTime())
    ) {
    // If the start date is later than the end date, swap them
    if (selectedRange.value.startDate.getTime() > selectedRange.value.endDate.getTime()) {
      [selectedRange.value.startDate, selectedRange.value.endDate] = [selectedRange.value.endDate, selectedRange.value.startDate];
      [selectedRange.value.startTime, selectedRange.value.endTime] = [selectedRange.value.endTime, selectedRange.value.startTime];
    }
    const data = {
      startDate: selectedRange.value.startDate,
      startTime: selectedRange.value.startTime,
      endDate: selectedRange.value.endDate,
      endTime: selectedRange.value.endTime,
    };
    emit('rangeSelected', data);
  }
};

const pendingBookings = computed(() => {
  const pendingDays = props.bookedDays.filter((day) => day.accepted === null);
  const formattedDays = [];
  pendingDays.forEach((day) => {
    formattedDays.push({
      start: new Date(day.start_date).getTime(),
      end: new Date(day.end_date).getTime(),
    });
  });
  return formattedDays;
});
const acceptedBookings = computed(() => {
  const acceptedDays = props.bookedDays.filter((day) => day.accepted === true);
  const formattedDays = [];
  acceptedDays.forEach((day) => {
    formattedDays.push({
      start: new Date(day.start_date).getTime(),
      end: new Date(day.end_date).getTime(),
    });
  });
  return formattedDays;
});

const workingHours = computed(() => {
  const formattedDays = [];
  props.workingHours.forEach((day) => {
    formattedDays.push({
      start: new Date(day.start_date).getTime(),
      end: new Date(day.end_date).getTime(),
    });
  });
  return formattedDays;
});

const isBookedDay = (day, part) => {
  if (day === null) {
    return false;
  }
  const formattedDate = new Date(day.getFullYear(), day.getMonth(), day.getDate(), formatTimeByDayPart(part), 0, 0).getTime();
  return pendingBookings.value.some((booking) => {
    return booking.start <= formattedDate && booking.end >= formattedDate;
  }) || acceptedBookings.value.some((booking) => {
    return booking.start <= formattedDate && booking.end >= formattedDate;
  });
};

const isAlreadyFilled = (day, time) => {
  if (day === null) {
    return false;
  }
  const formattedDate = new Date(day.getFullYear(), day.getMonth(), day.getDate(), formatTimeByDayPart(time), 0, 0).getTime();
  return workingHours.value.some((workingHour) => {
    return workingHour.start <= formattedDate && workingHour.end >= formattedDate;
  });
};

const addDayPartClasses = (day, part) => {
  const formattedDate = new Date(day.getFullYear(), day.getMonth(), day.getDate(), formatTimeByDayPart(part), 0, 0).getTime();
  const classes = [];
  if (day !== null) {
    if (pendingBookings.value.some((booking) => booking.start <= formattedDate && booking.end >= formattedDate)) {
      classes.push('booked pending');
    }
    if (acceptedBookings.value.some((booking) => booking.start <= formattedDate && booking.end >= formattedDate)) {
      classes.push('booked accepted');
    }
    if (workingHours.value.some((workingHour) => workingHour.start <= formattedDate && workingHour.end >= formattedDate)) {
      classes.push('working');
    }
    if (selectedRange.value.startDate !== null && selectedRange.value.endDate !== null) {
      const startFormattedDate = new Date(
        selectedRange.value.startDate.getFullYear(),
        selectedRange.value.startDate.getMonth(),
        selectedRange.value.startDate.getDate(),
        formatTimeByDayPart(selectedRange.value.startTime), 0, 0)
      .getTime();
      const endFormattedDate = new Date(
        selectedRange.value.endDate.getFullYear(),
        selectedRange.value.endDate.getMonth(),
        selectedRange.value.endDate.getDate(),
        formatTimeByDayPart(selectedRange.value.endTime), 0, 0)
      .getTime();
      const isSelected = (
        (formattedDate >= startFormattedDate && formattedDate <= endFormattedDate) ||
        (formattedDate <= startFormattedDate && formattedDate >= endFormattedDate)
      )

      if (isSelected) {
        classes.push('selected');
      }
    }
  }
  return classes;
};

onMounted(() => {
  calculateDisplayedDays(props.startingDate);
  if (props.disableClicks) {
    document.addEventListener('mouseup', (e) => {
      e.stopPropagation();
      e.preventDefault();
    }, true);
  }
});
</script>
