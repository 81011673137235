import { defineStore } from 'pinia'


export const useBookingsStore = defineStore('bookings', {
  state: () => ({
    bookings: [],
  }),
  getters: {
    getBookingsByUserId(state) {
      return (userId) => {
        return state.bookings[userId] ? state.bookings[userId].bookings : [];
      };
    },
  },

  actions: {
    setBookings(bookings) {
      this.bookings = bookings;
    },

    addBooking(booking, userId) {
      if (this.bookings[userId]) {
        this.bookings[userId].bookings.push(booking);
      } else {
        this.bookings[userId].bookings = [booking];
      }
      console.log(this.bookings);
    },

    modifyBooking(booking, userId) {
      console.log(booking, userId);
      console.log(this.bookings);
      const index = this.bookings[userId].bookings.findIndex(b => b.id === booking.id);
      this.bookings[userId].bookings[index] = booking;
    },

    deleteBooking(id, userId) {
      const index = this.bookings[userId].bookings.findIndex(b => b.id === id);
      this.bookings[userId].bookings.splice(index, 1);
    }

  },
})
