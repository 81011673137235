import { createRouter, createWebHistory } from 'vue-router';
import AbsencesView from '../views/AbsencesView.vue';
import LoginView from '@/views/LoginView';
import ResetPasswordView from '@/views/ResetPasswordView';
import NewPasswordView from '@/views/NewPasswordView';
import MyCalendarView from '@/views/MyCalendarView';
import UsersView from '@/views/UsersView';
import HolidaysView from '@/views/HolidaysView';
import MyAccountView from '@/views/MyAccountView';
import WorkingHoursView from '@/views/WorkingHoursView';
import CompanyView from '@/views/CompanyView';

import { useUserStore } from '@/stores/user';
import { useMenuStore } from '@/stores/menu';

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/absences',
  },

  {
    path: '/working-hours',
    name: 'WorkingHours',
    component: WorkingHoursView,
    meta: {
      title: 'Munkaidő | Timebookr'
    }
  },

  {
    path: '/absences',
    name: 'Absences',
    component: AbsencesView,
    meta: {
      title: 'Szabadságok | Timebookr'
    }
  },

  {
    path: '/my-calendar',
    name: 'MyCalendar',
    component: MyCalendarView,
    meta: {
      title: 'Naptár | Timebookr'
    }
  },

  {
    path: '/users',
    name: 'Users',
    component: UsersView,
    meta: {
      title: 'Felhasználók | Timebookr'
    }
  },

  {
    path: '/holidays',
    name: 'Holidays',
    component: HolidaysView,
    meta: {
      title: 'Ünnepnapok | Timebookr'
    }
  },

  {
    path: '/company',
    name: 'Company',
    component: CompanyView,
    meta: {
      title: 'Cég | Timebookr'
    }
  },

  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccountView,
    meta: {
      title: 'Profilom | Timebookr'
    }
  },

  {
    path: '/auth',
    name: 'Auth',
    redirect: '/auth/login',
    children: [
      {
        path: 'login',
        name: 'Login',
        component: LoginView,
        meta: {
          title: 'Bejelentkezés'
        }
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: ResetPasswordView,
        meta: {
          title: 'Jelszó visszaállítása'
        }
      },
      {
        path: 'new-password/:token',
        name: 'NewPassword',
        component: NewPasswordView,
        meta: {
          title: 'Új jelszó'
        }
      }
    ],
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  useMenuStore().setIsOpened(false);
  document.title = to.meta.title;
  const publicViewNames = [
    'Login',
    'Signup',
    'ForgottenPassword',
    'ResetPassword',
    'NewPassword',
  ];
  const loggedIn = useUserStore().isLoggedIn();
  const toPublicView = publicViewNames.includes(to.name);

  if (!loggedIn && !toPublicView) {
    return next('/auth/login');
  }
  if (loggedIn && toPublicView) {
    return next('/absences');
  }

  next();
});

export default router;
