import { defineStore } from 'pinia'


export const useMenuStore = defineStore('menu', {
  state: () => ({
    isOpened: false,
  }),

  getters: {
    getIsOpened(state) {
      return state.isOpened
    },
  },

  actions: {
    setIsOpened(isOpened) {
      this.isOpened = isOpened
    },
  }
})
