<template>
  <div class="company-view">
    <div class="container">

      <div class="header">
        <div class="title">Cég kezelése</div>
      </div>

      <div class="company-edit-form">
        <div class="label">Cég neve</div>
        <input
          v-model="companyName"
          type="text"
          placeholder="Cég neve*"
        />

        <div class="title">
          Munkaidő beállítások
        </div>

        <label class="notification">
         <input class="checkbox" type="checkbox" v-model="isNotificationEnabled" />
         Értesítés kitöltetlen munkaórákról
        </label>

        <div class="number-of-notifications">
          <div class="textual">Kitöltetlen munkaóra értesítések száma (0-14)</div>
          <input type="number" min="0" max="14" v-model="numberOfNotifications"/>
        </div>

      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore();

const companyName = ref('');
const isNotificationEnabled = ref(false);
const numberOfNotifications = ref(0);

if (userStore.theAccount) {
  companyName.value = userStore.theAccount.company.name;
}
watch(userStore, (newValue) => {
  if (newValue) {
    companyName.value = newValue.theAccount.company.name;
  }
})


watch(numberOfNotifications, (newValue) => {
  if (newValue === 0) {
    isNotificationEnabled.value = false;
  } else {
    isNotificationEnabled.value = true;
  }
})

watch(isNotificationEnabled, (newValue) => {
  if (newValue === false) {
    numberOfNotifications.value = 0;
  } else {
    numberOfNotifications.value = 1;
  }
})

</script>
