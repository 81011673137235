<template>
  <div class="auth-page login-form-view">

    <i class="icon logo" />
    <div class="page-title">Bejelentkezés</div>

    <div class="input-group">
      <input
        v-model="email"
        type="email"
        placeholder="Email cím"
        :class="{ error: emailError }"
      />
      <div
        class="error-message"
        v-if="true">{{ emailError }}
      </div>
    </div>

    <div class="input-group">
      <input
        v-model="password"
        type="password"
        placeholder="Jelszó"
        :class="{ error: passwordError }"
        @keyup.enter="submit()"
      />
      <div
        class="error-message"
        v-if="true"
      >
        {{ passwordError }}
      </div>
      <router-link to="/auth/reset-password">
        Elfelejtetted a jelszavad?
      </router-link>
    </div>

    <button class="button primary" :disabled="isLoading" @click="submit()">
      <i
        class="icon loader white"
        v-if="isLoading"
        :loading="isLoading"
        color="#ffffff"
        size="6px"
      />
      <template v-else>Bejelentkezés</template>
    </button>

  </div>
</template>

<script setup>
//import { UnauthorizedError } from '@/errors';
import { isValidEmail } from '@/utils/validators';
import { ref } from 'vue';
import { toast } from 'vue3-toastify';
import { apiService } from '@/composables/useApiService'
import { useUserStore } from '@/stores/user';

const email = ref('');
const password = ref('');
const isLoading = ref(false);

const emailError = ref('');
const passwordError = ref('');

const resetForm = () => {
  email.value = '';
  password.value = '';
};

const submit = () => {
  if (!validate()) {
    return;
  }

  isLoading.value = true;


  apiService.auth.login(email.value, password.value)
    .then(resp => {
      if (resp.code == 422) {
        toast.error('Sikertelen bejelentkezés, hibás adatok!')
        return
      }
      else if (resp.code == 401) {
        toast.error('Sikertelen bejelentkezés, hibás adatok!')
        return
      }


      const userStore = useUserStore()
      userStore.fetchMe(true)
        .then(() => {
          // Redirect must be done this way to bypass VUE ROUTER's
          // "history API" only change and to forces website to make
          // new page request.
          window.location = '/absences'
        })
    }).finally(() => isLoading.value = false)

};

const validate = () => {
  let isValid = true;
  emailError.value = '';
  passwordError.value = '';

  if (!email.value) {
    emailError.value = 'Email megadása kötelező.';
    isValid = false;
  } else if (!isValidEmail(email.value)) {
    emailError.value = 'Email cím formátuma nem megfelelő.';
    isValid = false;
  }

  if (!password.value) {
    passwordError.value = 'Jelszó megadása kötelező.';
    isValid = false;
  }

  return isValid;
};
</script>
