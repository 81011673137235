import { defineStore } from "pinia";

export const useWorkingHoursStore = defineStore("workingHours", {
  state: () => ({
    workingHours: {},
  }),
  getters: {
    getWorkingHoursByUserId(state) {
      return (userId) => {
        return state.workingHours[userId]? state.workingHours[userId] : [];
      };
    },
  },
  actions: {
    setWorkingHours(workingHours) {
      workingHours.forEach(workingHour => {
        const userId = workingHour.user.id;
        if (this.workingHours[userId]) {
          this.workingHours[userId].push(workingHour);
        } else {
          this.workingHours[userId] = [workingHour];
        }
      });
    },

    addWorkingHour(workingHour) {
      const userId = workingHour.user.id;
      if (this.workingHours[userId]) {
        this.workingHours[userId].push(workingHour);
      } else {
        this.workingHours[userId] = [workingHour];
      }
    },

    modifyWorkingHour(workingHour, userId) {
      const index = this.workingHours[userId].findIndex(b => b.id === workingHour.id);
      this.workingHours[userId][index] = workingHour;
    },

    deleteWorkingHour(id, userId) {
      const index = this.workingHours[userId].findIndex(b => b.id === id);
      this.workingHours[userId].splice(index, 1);
    }
  },
});
