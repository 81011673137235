<template>
  <div class="view holidays-view">
    <div v-if="!isPageLoading" class="container">
      <div class="holidays-header">
        <div class="year">
          <i class="icon arrow-left" @click="previousYear" />
          <div class="textual">{{ currentYear }}</div>
          <i class="icon arrow-right" @click="nextYear" />
        </div>
        <input
          v-model="search"
          class="search-input"
          type="text"
          placeholder="Keresés"
        />
      </div>

      <div class="holiday add-new">
        <DropdownSelect
          v-model="newHoliday.country_code"
          :options="countries"
          label="name"
          value="code"
          dropdownPlaceholder="Ország"
          :searchable="true"
        />
        <Datepicker
          v-model="newHoliday.date"
          placeholder="Dátum*"
        />
        <input
          v-model="newHoliday.note"
          type="text"
          placeholder="Leírás*"
        />
        <button
          class="add-new-button"
          @click="saveHoliday"
        >
          <i class="icon loader white" v-if="isLoading" />
          <template v-else>
            <i class="icon plus white" />
            Új ünnep
          </template>

        </button>
      </div>

      <div
        v-if="holidays.length !== 0"
        class="existing-holidays"
      >
        <div
          v-for="(holiday, index) in holidays"
          :key="index"
          class="holiday"
        >
          <DropdownSelect
            v-model="holiday.country_code"
            :options="countries"
            label="name"
            value="code"
            :disabled="!(editingId===holiday.id)"
          />
          <Datepicker
            v-model="holiday.holiday_date"
            placeholder="Dátum*"
            :disabled="!(editingId===holiday.id)"
          />
          <input
            v-model="holiday.note"
            type="text"
            placeholder="Leírás*"
            :disabled="!(editingId===holiday.id)"
          />
          <div class="actions" v-if="!(editingId===holiday.id)">
            <div
              class="modify"
              @click="modifyHoliday(index)"
            >
              <i class="icon edit" />
            </div>
            <div
              class="delete"
              @click="deleteHoliday(holiday.id)"
            >
              <i v-if="deletingId===holiday.id" class="icon loader" />
              <i v-else class="icon delete" />
            </div>
          </div>
          <div class="actions" v-else>
            <button
              class="button primary"
              @click="saveModifiedHoliday(index)"
            >
              <template v-if="true">Mentés</template>
              <i v-else class="icon loader white" />
            </button>
            <div
              class="button secondary"
              @click="cancelModifyHoliday(index)"
            >
              Mégse
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="existing-holidays"
      >
        <div class="no-holidays">
          Nincs megjeleníthető ünnep
        </div>
      </div>

    </div>
    <div v-else class="loader-container">
      <i class="icon loader black" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue';
import { useHolidayStore } from '@/stores/holidays';
import { apiService } from '@/composables/useApiService';
import { toast } from 'vue3-toastify';
import Datepicker from 'vue3-datepicker'
import DropdownSelect from '@/components/DropdownSelect.vue';

const countryCodes = require('country-codes-list');
const countriesObject = countryCodes.customList('countryCode', '{countryNameEn}');
const countries = Object.entries(countriesObject).map(([code, name]) => ({ code: code.toLowerCase(), name }));

const holidayStore = useHolidayStore();

const isPageLoading = ref(false);

const search = ref('');
const currentYear = ref(new Date().getFullYear());
const newHoliday = ref({});
const isLoading = ref(false);
const deletingId = ref(null);
const editingId = ref(null);
const isModifyingLoading = ref(false);
const originalModifiedHoliday = ref({});

const holidays = computed(() => {
  return holidayStore.getHolidays.filter(holiday => {
    return holiday.holiday_date.getFullYear() === currentYear.value && holiday.note.toLowerCase().includes(search.value.toLowerCase());
  });
});

const saveHoliday = async () => {
  if (!newHoliday.value.date) {
    toast.error('Dátum megadása kötelező');
    return;
  }

  if (!newHoliday.value.note) {
    toast.error('Leírás megadása kötelező');
    return;
  }

  if (!newHoliday.value.country_code) {
    toast.error('Ország megadása kötelező');
    return;
  }

  if ( holidays.value.some(
      holiday => holiday.holiday_date.getFullYear() === newHoliday.value.date.getFullYear()
      && holiday.holiday_date.getMonth() === newHoliday.value.date.getMonth()
      && holiday.holiday_date.getDate() === newHoliday.value.date.getDate()
    )) {
    toast.error('Ez a dátum már szerepel a listában');
    newHoliday.value = {};
    return;
  }

  isLoading.value = true;

  await apiService.holidays.postHoliday(newHoliday.value.note, newHoliday.value.date, newHoliday.value.country_code).then((resp) => {
    holidayStore.addHoliday(resp.payload);
    toast.success('Sikeres mentés');
    newHoliday.value = {};
    isLoading.value = false;
  }).catch((e) => {
    toast.error('Sikertelen mentés');
    isLoading.value = false;
  });
};

const modifyHoliday = (index) => {
  const id = holidays.value[index].id;
  editingId.value = id;
  originalModifiedHoliday.value = JSON.parse(JSON.stringify(holidays.value[index]));
};

const saveModifiedHoliday = (index) => {
  isModifyingLoading.value = true;
  const id = holidays.value[index].id;
  editingId.value = id;
  if (id) {
    apiService.holidays.putHoliday(id, holidays.value[index].note, holidays.value[index].holiday_date, holidays.value[index].country_code).then((resp) => {
      holidayStore.updateHoliday(resp.payload)
      toast.success('Sikeres mentés');
    }).catch((e) => {
      toast.error('Sikertelen mentés');
    }).finally(() => {
      editingId.value = null;
      isModifyingLoading.value = false;
    })
  }
}

const cancelModifyHoliday = (index) => {
  editingId.value = null;
  isModifyingLoading.value = false;
  originalModifiedHoliday.value.holiday_date = new Date(originalModifiedHoliday.value.holiday_date);
  holidays.value[index] = originalModifiedHoliday.value;
  originalModifiedHoliday.value = {};
};

const deleteHoliday = async (id) => {
  deletingId.value = id;
  if (id) {
    await apiService.holidays.deleteHoliday(id).then((resp) => {
      holidayStore.deleteHoliday(id);
      toast.success('Sikeres törlés');
    }).catch((e) => {
      toast.error('Sikertelen törlés');
    }).finally(() => {
      deletingId.value = null;
    })
  }
};

const previousYear = () => {
  currentYear.value--;
};

const nextYear = () => {
  currentYear.value++;
};

watchEffect(() => {
  if (holidays.value.length === 0) {
    isPageLoading.value = true;
  } else {
    isPageLoading.value = false;
  }
});
</script>



