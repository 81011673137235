<template>
  <div class="view users-view">
    <div v-if="!isLoading" class="container">
      <UserFormModal
        v-if="isModalVisible"
        :user="selectedUser"
        @close="hideModal"
      />

      <div class="header">
        <div class="left">
          <div
            class="view-switch"
            @click="toggleSelectedView"
          >
            {{selectedViewOptions[selectedView]}}
          </div>
          <input
            v-model="search"
            class="search-input"
            type="text"
            placeholder="Keresés"
          />
        </div>
        <div class="right">
          <button
            class="open-modal-button"
            @click="showModal"
          >
            <i class="icon plus white" />
            Új felhasználó
          </button>
        </div>
      </div>

      <table class="users-list">
        <thead>
          <tr>
            <th>Név</th>
            <th>Kezdődátum</th>
            <th>Szabadságok száma</th>
            <th>Opciók</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="!filteredUsers.length"
          >
            <td class="no-user" colspan="4">Nincs megjeleníthető felhasználó</td>
        </tr>
          <tr v-for="user in paginatedUsers" :key="user.id">
            <td><PersonInfo :person="user"/></td>
            <td>{{ formatDate(user.commencement_date) }}</td>
            <td>{{ user.allowance }}</td>
            <td>
              <i
                class="icon edit"
                @click="showModalWithUser(user)"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pager">
        <i class="icon arrow-left" @click="previousPage"/>
        <div class="page">{{ currentPage }}/{{ totalPages }}</div>
        <i class="icon arrow-right" @click="nextPage"/>
      </div>

    </div>
    <div v-else class="loader-container">
      <i class="icon loader black" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useUserStore } from '@/stores/user';
import UserFormModal from '@/components/UserFormModal.vue';
import PersonInfo from '@/components/PersonInfo.vue';


const userStore = useUserStore()

const isModalVisible = ref(false)
const isLoading = ref(false)

const selectedViewOptions = ['Aktív', 'Törölt']
const selectedView = ref(0)
const search = ref('')
const selectedUser = ref(null)

const itemsPerPage = 10
const currentPage = ref(1)

const users = computed(() => {
  if (userStore.getUsers) {
    return userStore.getUsers
  }
  return []
})

const toggleSelectedView = () => {
  selectedView.value = selectedView.value === 0 ? 1 : 0
}

const showModal = () => {
  isModalVisible.value = true
}
const showModalWithUser = (user) => {
  selectedUser.value = user
  showModal()
}
const hideModal = () => {
  isModalVisible.value = false
}


const formatDate = (date) => {
  const newDate = new Date(date)
  return `${newDate.getFullYear()}.${newDate.getMonth() + 1}.${newDate.getDate()}`
}

const putNamesTogether = (user) => {
  return `${user.last_name} ${user.first_name}`
}

// Filter users based on the search
const filteredUsers = computed(() => {
  return users.value.filter(user => {
    return putNamesTogether(user).toLowerCase().includes(search.value.toLowerCase())
  })
})

// Paginate the filtered users
const paginatedUsers = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  return filteredUsers.value.slice(startIndex, endIndex)
})
// Calculate the total number of pages
const totalPages = computed(() => {
  return Math.ceil(filteredUsers.value.length / itemsPerPage)
})

const previousPage = () => {
  if (currentPage.value > 1) {
    currentPage.value -= 1
  }
}

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value += 1
  }
}

watchEffect(() => {
  if (!users.value.length) {
    isLoading.value = true
  }
  else {
    isLoading.value = false
  }
})

</script>
