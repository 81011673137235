import { defineStore } from "pinia";

export const useLeaveTypeStore = defineStore('leaveType', {
  state: () => ({
    leaveTypes: [],
  }),

  getters: {
    getLeaveTypes: (state) => state.leaveTypes,
  },

  actions: {
    setLeaveTypes(leaveTypes) {
      this.leaveTypes = leaveTypes
    },

    addLeaveType(leaveType) {
      this.leaveTypes.push(leaveType)
    },

    updateLeaveType(leaveType) {
      const index = this.leaveTypes.findIndex(h => h.id === leaveType.id)
      this.leaveTypes[index] = leaveType
    },

    deleteLeaveType(id) {
      this.leaveTypes = this.leaveTypes.filter(h => h.id !== id)
    },
  }
})
