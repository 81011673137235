<template>
  <div class="calendar year-calendar">
    <div class="year-grid">
      <div
        v-for="(month, index) in months"
        :key="index"
        class="month-grid"
      >
        <div class="month-name">{{ month.name }}</div>
        <div class="day-grid">
          <div class="day-name">H</div>
          <div class="day-name">K</div>
          <div class="day-name">Sz</div>
          <div class="day-name">Cs</div>
          <div class="day-name">P</div>
          <div class="day-name">Sz</div>
          <div class="day-name">V</div>
          <div
            v-for="(day, dayIndex) in month.days"
            :key="dayIndex"
            :class="['day-square', { 'nwd': isWeekend(dayIndex, day), 'disabled': isDisabled(day), 'today': isToday(props.year, month.monthIndex, day), 'holiday': isHoliday(day) }]"
            :titleToShow=addHolidayTitle(day)
          >
            <div class="day-number">
              {{ day !== null ? day.getDate() : '' }}
            </div>
            <div
            class="day-part"
            :class="[
                addDayPartClasses(day, 0),
                { selected: isDayPartSelected(day, 0, selectedRange) }
              ]"
              @click="handleDayPartClick(month, day, 0)"
              @mousedown="handleMouseDown($event, day, 0)"
              @mouseup="handleMouseUp(day, 0)"
              @mouseover="handleMouseOver($event, day, 0)"
            >
            </div>
            <div
              class="day-part"
              :class="[
                addDayPartClasses(day, 1),
                { selected: isDayPartSelected(day, 1, selectedRange) }
              ]"
              @click="handleDayPartClick(month, day, 1)"
              @mousedown="handleMouseDown($event, day, 1)"
              @mouseup="handleMouseUp(day, 1)"
              @mouseover="handleMouseOver($event, day, 1)"
            >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useHolidayStore } from '@/stores/holidays';
import  { isDayPartSelected, formatTimeByDayPart }  from '@/utils/date';

const holidayStore = useHolidayStore();
const holidays = computed(() => holidayStore.getHolidays);

const emit = defineEmits(['update:modelValue', 'dayClicked', 'bookingClicked', 'rangeSelected']);

const selectedRange = ref({
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
});

const props = defineProps({
  year: {
    type: Number,
    default: new Date().getFullYear(),
  },
  bookedDays: {
    type: Array,
    default: () => [],
  },
});

//calculate on which dayname the first day of the month is
const monthFirstDays = computed(() =>
  Array.from({ length: 12 }, (_, month) => new Date(props.year, month, 1).getDay())
    .map(dayIndex => (dayIndex + 6) % 7)
);

//returns the number of days in a month
const monthDays = computed(() =>
  Array.from({ length: 12 }, (_, month) => new Date(props.year, month + 1, 0).getDate())
);

//decides whether a day is a weekend or not
const isWeekend = (dayIndex, day) => {
  const actualDayIndex = (dayIndex) % 7;
  // Check if it's a Saturday (5) or Sunday (6), if it is, check if the day is not null
  return (actualDayIndex === 5 || actualDayIndex === 6) && day !== null;
};

const isHoliday = (day) => {
  if (day === null) {
    return false;
  }
  return holidays.value.some((holiday) => {
    const date = new Date(holiday.holiday_date);
    return date.getFullYear() === day.getFullYear()
      && date.getMonth() === day.getMonth()
      && date.getDate() === day.getDate();
  });
};
const addHolidayTitle = (day) => {
  if (day === null) {
    return '';
  }
  if (isHoliday(day)) {
    return holidays.value.find((holiday) => {
      const date = new Date(holiday.holiday_date);
      return date.getFullYear() === day.getFullYear()
        && date.getMonth() === day.getMonth()
        && date.getDate() === day.getDate();
    }).note;
  }
  return '';
};

const isDisabled = (day) => {
  return day === null;
};

const isToday = (year, monthIndex, day) => {
  const today = new Date();
  return (
    year === today.getFullYear() &&
    monthIndex === today.getMonth() &&
    day === today.getDate()
  );
};

const monthNames = [
  'Január',
  'Február',
  'Március',
  'Április',
  'Május',
  'Június',
  'Július',
  'Augusztus',
  'Szeptember',
  'Október',
  'November',
  'December',
];
//returns an array of months with the days in them
const months = computed(() =>
  Array.from({ length: 12 }, (_, month) => {
    const days = Array.from({ length: monthDays.value[month] }, (_, day) => new Date(props.year, month, day + 1));
    const startingDay = monthFirstDays.value[month];
    return { name: monthNames[month], days: [...Array(startingDay).fill(null), ...days], monthIndex: month };
  })
);

const handleDayPartClick = (month, day, time) => {
  if (isBookedDay(day)) {
    const booking = props.bookedDays.find((booking) => {
      return new Date(booking.start_date).getTime() <= day.getTime()
        && new Date(booking.end_date).getTime() >= day.getTime();
    });
    emit('bookingClicked', booking);
    return;
  }
  if (
      day !== null
      && !isWeekend(month.startingDay + day - 1, day)
      && !isDisabled(day)
      && new Date(day) >= new Date()
    ) {
    const data = {
      year: day.getFullYear(),
      month: day.getMonth(),
      day: day.getDate(),
      time: time,
      new: true,
    };

    emit('dayClicked', data);
  }
};


const pendingBookings = computed(() => {
  const pendingDays = props.bookedDays.filter((day) => day.accepted === null);
  const formattedDays = [];
  pendingDays.forEach((day) => {
    formattedDays.push({
      start: new Date(day.start_date).getTime(),
      end: new Date(day.end_date).getTime(),
    });
  });
  return formattedDays;
});
const acceptedBookings = computed(() => {
  const acceptedDays = props.bookedDays.filter((day) => day.accepted === true);
  const formattedDays = [];
  acceptedDays.forEach((day) => {
    formattedDays.push({
      start: new Date(day.start_date).getTime(),
      end: new Date(day.end_date).getTime(),
    });
  });
  return formattedDays;
});

const isBookedDay = (day) => {
  if (day === null) {
    return false;
  }
  return pendingBookings.value.some((booking) => {
    return booking.start <= day.getTime() && booking.end >= day.getTime();
  }) || acceptedBookings.value.some((booking) => {
    return booking.start <= day.getTime() && booking.end >= day.getTime();
  });
};


const addDayPartClasses = (day, part) => {
  if (day === null) {
    return '';
  }
  const formattedDate = new Date(day.getFullYear(), day.getMonth(), day.getDate(), formatTimeByDayPart(part), 0, 0).getTime();
  const classes = [];
  if (day !== null) {
    if (pendingBookings.value.some((booking) => booking.start < formattedDate && booking.end > formattedDate)) {
      classes.push('booked pending');
    }
    if (acceptedBookings.value.some((booking) => booking.start <= formattedDate && booking.end >= formattedDate)) {
      classes.push('booked accepted');
    }
    if (selectedRange.value.startDate !== null && selectedRange.value.endDate !== null) {
      const startFormattedDate = new Date(selectedRange.value.startDate.getFullYear(), selectedRange.value.startDate.getMonth(), selectedRange.value.startDate.getDate(), formatTimeByDayPart(selectedRange.value.startTime), 0, 0).getTime();
      const endFormattedDate = new Date(selectedRange.value.endDate.getFullYear(), selectedRange.value.endDate.getMonth(), selectedRange.value.endDate.getDate(), formatTimeByDayPart(selectedRange.value.endTime), 0, 0).getTime();
      const isSelected = (
        (formattedDate >= startFormattedDate && formattedDate <= endFormattedDate) ||
        (formattedDate <= startFormattedDate && formattedDate >= endFormattedDate)
      )

      if (isSelected) {
        classes.push('selected');
      }
    }
  }
  return classes;
};

const handleMouseDown = (e, day, time) => {
  if (day === null  || isHoliday(day) || isBookedDay(day) || day < new Date()) {
    return;
  }
  e.target.classList.add('selected');
  selectedRange.value.startDate = day;
  selectedRange.value.startTime = time;
};

// If the mouse is over a day, and the start date is not null, set the end date to the day
const handleMouseOver = (e, day, time) => {
  if (selectedRange.value.startDate === null) {
    return;
  }
  if (selectedRange.value.startDate ) {
    selectedRange.value.endDate = day;
    selectedRange.value.endTime = time;
  }
};
// If the mouse is up, set the end date to the day
const handleMouseUp = (day, time) => {
  selectedRange.value.endDate = day;
  selectedRange.value.endTime = time;
  emitRangeSelected();

  removeAllSelection();
  selectedRange.value = {
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  };
};

const emitRangeSelected = () => {
  // If the start date and end date are not null and they are not the same
  if (selectedRange.value.startDate
    && selectedRange.value.endDate
    && (selectedRange.value.startDate.getTime() != selectedRange.value.endDate.getTime())
    ) {
    // If the start date is later than the end date, swap them
    if (selectedRange.value.startDate.getTime() > selectedRange.value.endDate.getTime()) {
      [selectedRange.value.startDate, selectedRange.value.endDate] = [selectedRange.value.endDate, selectedRange.value.startDate];
      [selectedRange.value.startTime, selectedRange.value.endTime] = [selectedRange.value.endTime, selectedRange.value.startTime];
    }
    const data = {
      startDate: selectedRange.value.startDate,
      startTime: selectedRange.value.startTime,
      endDate: selectedRange.value.endDate,
      endTime: selectedRange.value.endTime,
    };
    emit('rangeSelected', data);
  }
};



const removeAllSelection = () => {
  const selected = document.querySelectorAll('.selected');
  selected.forEach((element) => {
    element.classList.remove('selected');
  });
};
</script>
