const namespace = 'holiday'

export default ($request) => ({

  getHolidays() {
    return $request.get(`${namespace}s`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  getHolidayById(id) {
    return $request.get(`${namespace}/${id}`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  postHoliday(note, holiday_date, country_code) {
    const year = holiday_date.getFullYear();
    const month = (holiday_date.getMonth() + 1).toString().padStart(2, '0');
    const day = holiday_date.getDate().toString().padStart(2, '0');
    const data = {
      "note": note,
      "holiday_date": year + '-' + month + '-' + day,
      "country_code": country_code.toLowerCase()
    }

    return $request.post(`${namespace}`, data)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  putHoliday(id, note, holiday_date, country_code) {
    const year = holiday_date.getFullYear();
    const month = (holiday_date.getMonth() + 1).toString().padStart(2, '0');
    const day = holiday_date.getDate().toString().padStart(2, '0');
    const data = {
      "note": note,
      "holiday_date": year + '-' + month + '-' + day,
      "country_code": country_code.toLowerCase()
    }
    return $request.put(`${namespace}/${id}`, data)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  deleteHoliday(id) {
    return $request.delete(`${namespace}/${id}`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

})
