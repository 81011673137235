<template>
  <div class="auth-page forgotten-password-view">
    <i class="icon logo" />
    <div class="page-title">Elfelejtett jelszó</div>

    <div class="input-group">
      <input
        v-model="email"
        type="email"
        placeholder="Email cím"
        :class="{ error: emailError }"
        @input="emailError = ''"
      />
      <div
        class="error-message"
        v-if="true">
        {{ emailError }}
        </div>
    </div>

    <button class="button primary" :disabled="isLoading" @click="submit()">
      <i
        class="icon loader white"
        v-if="isLoading"
        :loading="isLoading"
        color="#ffffff"
        size="6px"
      />
      <template v-else>Jelszó visszaállítása</template>
    </button>

    <div class="secondary-links">
      <router-link to="/auth/login">Vissza a bejelentkezéshez</router-link>
    </div>
  </div>
</template>

<script setup>
import { isValidEmail } from '@/utils/validators';
import { ref } from 'vue';

const email = ref('');
const isLoading = ref(false);
const emailError = ref('');

const submit = () => {
  if (!validate()) {
    return;
  }

  isLoading.value = true;

};

const validate = () => {
  emailError.value = '';
  if (!email.value) {
    emailError.value = 'Email megadása kötelező.';
    return false;
  }

  if (!isValidEmail(email.value)) {
    emailError.value = 'Email cím formátuma nem megfelelő.';
    return false;
  }
  return true;
};
</script>
