import { defineStore } from "pinia";

export const useHolidayStore = defineStore('holiday', {
  state: () => ({
    holidays: [],
  }),

  getters: {
    getHolidays: (state) => state.holidays,
    getHolidayDates: (state) => state.holidays.map(h => h.holiday_date),
    getHolidayLengthByYear: (state) => (year) => state.holidays.filter(h => h.holiday_date.getFullYear() === year).length,
  },

  actions: {
    setHolidays(holidays) {
      this.holidays = holidays.map(h => ({
        id: h.id,
        holiday_date: new Date(h.holiday_date),
        note: h.note,
        country_code: h.country_code,
      }));
    },

    addHoliday(holiday) {
      const index = this.holidays.findIndex(h => new Date(h.holiday_date) > new Date(holiday.holiday_date));
      const newHoliday = {
        id: holiday.id,
        holiday_date: new Date(holiday.holiday_date),
        note: holiday.note,
        country_code: holiday.country_code,
      }
      if (index === -1) {
        this.holidays.push(newHoliday);
      } else {
        this.holidays.splice(index, 0, newHoliday);
      }
   },

    updateHoliday(holiday) {
      const index = this.holidays.findIndex(h => h.id === holiday.id)
      this.holidays[index] = holiday
    },

    deleteHoliday(id) {
      const index = this.holidays.findIndex(h => h.id === id)
      this.holidays.splice(index, 1)
    },
  }
})
