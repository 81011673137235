<template>
  <div
    class="dropdown-select"
    :class="{ disabled: disabled }"
  >
    <div class="dropdown-container" @click="toggleDropdown">
      <i
        class="icon dropdown"
        :class="{ rotated: isOpen }"
      />
      <input
        v-if="searchable && searching"
        class="search-input"
        ref="searchInput"
        v-model="searchTerm"
        type="text"
        @click="preventDefault()"
      />
      <div
        v-if="selectedOption.length === 0"
        class="selected-item placeholder"
      >
        {{ dropdownPlaceholder }}
      </div>
      <div
        v-else-if="selectedOption && !searching"
        class="selected-item"
      >
        {{ showWithLabel(selectedOption) }}
      </div>
    </div>
    <ul
      v-if="isOpen"
      class="dropdown-menu"
      :class="{hidden: !filteredOptions.length}"
      ref="dropdownMenu"
    >
      <li
        v-for="(option, index) in filteredOptions"
        :key="index"
        @click="selectOption(option)"
      >
        {{ showWithLabel(option) }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, computed, watch, nextTick } from 'vue';
const emit = defineEmits(['update:modelValue', 'open', 'close']);

const props = defineProps({
  dropdownPlaceholder: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  label: {
    type: String,
    default: null,
  },
  value: {
    type: String,
    default: null,
  },
  defaultSelectedOption: {
    default: '',
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    default: '',
  },
});

const searchInput = ref(null);
const searchTerm = ref('');
const searching = ref(false);

const filteredOptions = computed(() => {
  if (searchTerm.value) {
    if (props.label) {
      return props.options.filter((option) => {
        return option[props.label].toLowerCase().includes(searchTerm.value.toLowerCase());
      });
    }
    return props.options.filter((option) => {
      return option.toLowerCase().includes(searchTerm.value.toLowerCase());
    });

  }
  return props.options;
});

const computedSelectedOption = computed(() => {
  return props.defaultSelectedOption;
});

watch(computedSelectedOption, (newSelectedOption) => {
  selectedOption.value = newSelectedOption;
});

const isOpen = ref(false);
const selectedOption = ref('');
const dropdownMenu = ref(null);

const toggleDropdown = () => {
  event.stopPropagation();
  isOpen.value = !isOpen.value;
  if (isOpen.value) {
    emit('open');
    if(props.searchable){
      searching.value = true;
      nextTick(() => {
        searchInput.value.focus();
      });
    }

  }
  if (!isOpen.value) {
    closeDropdown();
  }
};

const closeDropdown = () => {
  isOpen.value = false;
  emit('close');
  searching.value = false;
  searchTerm.value = '';
};

const selectOption = (option) => {
  selectedOption.value = option;

  if (props.value) {
    emit('update:modelValue', option[props.value]);
  } else {
    emit('update:modelValue', option);
  }
  closeDropdown();
};

const handleClickOutside = (event) => {
  if (dropdownMenu.value && !dropdownMenu.value.contains(event.target)) {
    closeDropdown();
  }
};

const showWithLabel = (option) => {
  if (props.label) {
    return option[props.label];
  }
  return option;
};

const preventDefault = () => {
  event.stopPropagation();
};

watch(() => props.modelValue, (newVal) => {
  if (newVal.length === 0) {
    selectedOption.value = '';
  }
  else if (props.options.length > 0) {
    const foundOption = props.options.find(option => option[props.value] === newVal);
    if (foundOption) {
      selectedOption.value = foundOption;
    }
  }
});

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
  if (props.defaultSelectedOption) {
    selectedOption.value = props.defaultSelectedOption;
  }
  else if (props.value && props.options.length > 0) {
    const foundOption = props.options.find(option => option[props.value] === props.modelValue);
    if (foundOption) {
      selectedOption.value = foundOption;
    }
  }
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>
