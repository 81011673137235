<template>
  <div class="year-selector">

    <div class="calendar-selector">
      <div
        class="calendar-prev"
        @click="prevYear()"
      >
        <i class="icon arrow-left" />
      </div>

      <div
        class="calendar-next"
        @click="nextYear()"
      >
        <i class="icon arrow-right" />
      </div>
    </div>

    <span class="calendar-text">
      Januártól Decemberig {{ currentYear }}.
    </span>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
const emit = defineEmits(['update:modelValue', 'yearClicked']);

const minYear = parseInt(process.env.VUE_APP_MIN_YEAR);
const maxYear = parseInt(process.env.VUE_APP_MAX_YEAR);
const currentYear = ref(new Date().getFullYear());

const nextYear = () => {
  if (currentYear.value === maxYear){
    return;
  }
  currentYear.value++;
  emit('update:modelValue', currentYear.value);
};
const prevYear = () => {
  if (currentYear.value === minYear){
    return;
  }
  currentYear.value--;
  emit('update:modelValue', currentYear.value);
};

onMounted(() => {
  emit('update:modelValue', currentYear.value);
});
</script>
