import { defineStore } from "pinia";

import { useCookies } from '@vueuse/integrations/useCookies';
import { apiService } from '@/composables/useApiService'

// const getCookieOptions = (defaults = {}) => ({
//   maxAge: typeof defaults.maxAge == 'number' ? defaults.maxAge : undefined,
// })

export const COOKIE_BEARER_TOKEN_KEY = 'timebookr_token'

let rebuilt = false

export const useUserStore = defineStore('user', {
  state: () => ({
    //supposed to simulate login state, if false, only public routes are accessible, if true, all routes are accessible
    // loggedIn: true,

    account: null,
    token: null,
    token_type: null,
    expires_in: null,
    userFetched: false,
    users: []
  }),

  getters: {
    theAccount: (state) => state.account,
    theToken: (state) => () => {
      const cookies = useCookies()
      if (cookies.get(COOKIE_BEARER_TOKEN_KEY) !== null) {
        return cookies.get(COOKIE_BEARER_TOKEN_KEY)
      }

      return state.token
    },
    isUserFetched: (state) => state.userFetched,
    isAnonymous: (state) => state.token === null || state.account === null,
    isLoggedIn: () => () => {
      const cookies = useCookies()
      const token = cookies.get(COOKIE_BEARER_TOKEN_KEY)
      return typeof token === 'string' && token.length > 0
    },
    getUsers: (state) => state.users,
  },

  actions: {
    init(forced = false) {
      const hasAccountSet = (
        Object.prototype.toString.call(this.account) === '[object Object]'
        && this.account.id.length > 0
      )

      if (
        !(
          forced
          || !hasAccountSet
        )
      ) {
        return
      }

      try {
        const storedAccount = window.localStorage.getItem('account')
        if (storedAccount !== null) {
          this.account = JSON.parse(storedAccount);
        }

        const storedToken = window.localStorage.getItem('token')
        if (storedToken !== null) {
          this.token = storedToken;
        }

        const storedTokenType = window.localStorage.getItem('token_type')
        if (storedTokenType !== null) {
          this.token_type = storedTokenType;
        }

        const storedExpiresIn = window.localStorage.getItem('token_expires_in')
        if (storedExpiresIn !== null) {
          this.expires_in = parseInt(storedExpiresIn, 10);
        }

        const storedTokenCreated = window.localStorage.getItem('token_created')
        if (storedTokenCreated !== null && this.expires_in !== null) {
          const dt = (new Date())
          dt.setTime(parseInt(storedTokenCreated, 10))
          const dtExpireAt = new Date()
          dtExpireAt.setTime(dtExpireAt.getTime() + (this.expires_in * 1000))

          if (dt > dtExpireAt) {
            this.account = null
            this.token = null
            this.token_type = null
            this.expires_in = null

            window.localStorage.removeItem('account')
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('token_type')
            window.localStorage.removeItem('token_expires_in')
            window.localStorage.removeItem('token_created')
          }
        }
      } catch (e) {
        if (!rebuilt) {
          rebuilt = true;

          this.logout();
          this.init();
        }
      }
    },

    fetchMe(forced = false) {
      if (this.userFetched && !forced) {
        return Promise.resolve(true)
      }

      // IMPORTANT! This cannot be move INSIDE promise's callback due to Nuxt limitation!
      const cookies = useCookies()
      const token = cookies.get(COOKIE_BEARER_TOKEN_KEY)
      if (typeof token === 'string' && token.length > 0) {
        this.token = token
      }

      return apiService.user.me()
        .then((resp) => {
          if (resp && resp.code && resp.code === 200) {
            this.userFetched = true
            this.account = resp.payload
          }

          return resp
        })
    },

    setUsers(users) {
      this.users = users
    },

    login(data) {
      this.account = data.user;
      this.token = data.access_token;
      this.token_type = data.token_type;
      this.expires_in = data.expires_in;

      window.localStorage.setItem('account', JSON.stringify(data.user))
      window.localStorage.setItem('token', data.access_token)
      window.localStorage.setItem('token_type', data.token_type)
      window.localStorage.setItem('token_expires_in', data.expires_in)
      window.localStorage.setItem('token_created', Date.now().toString())

      const cookies = useCookies()
      cookies.set(COOKIE_BEARER_TOKEN_KEY, this.token, { path: '/', secure: true })
    },

    logout() {
      const cookies = useCookies()
      cookies.remove(COOKIE_BEARER_TOKEN_KEY)

      window.localStorage.removeItem('account')
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('token_type')
      window.localStorage.removeItem('token_expires_in')
      window.localStorage.removeItem('token_created')

      this.account = null
      this.token = null
      this.token_type = null
      this.expires_in = null
    },

    setAccount(value) {
      this.account = value
    },

    setToken(value) {
      this.token = value

      const cookies = useCookies()
      cookies.set(COOKIE_BEARER_TOKEN_KEY, this.token, { path: '/', secure: true })
    },
  }

})

// const cookieOptionsOverrides = () => {
//   const runtimeConfig = useRuntimeConfig()

//   return {
//     maxAge: (typeof runtimeConfig.cookieMaxAge != 'undefined' && runtimeConfig.cookieMaxAge.toString().length > 0) ? parseInt(runtimeConfig.cookieMaxAge, 10) : undefined
//   }
// }

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
