<template>
  <div class="view dashboard-view">
    <div v-if="!isLoading" class="container">
      <DashboardHeader
        v-model="dateProp"
        @open="openModal()"
      />
      <BookTimeOffModal
        v-if="showModal"
        :startDate="modalStartDate"
        :startTime="modalStartTime"
        :endDate="modalEndDate"
        :endTime="modalEndTime"
        :userId="modalId"
        :existingBooking="modalBooking"
        @close="closeModal()"
      />


      <div class="persons-container">
        <div
          v-if="me"
          class="person me"
        >
          <PersonInfo
            :person="me"
          />
          <HorizontalMonthCalendar
            :startingDate="startingDate"
            :showHeader="true"
            :booked-days="bookings"
            @dayClicked="openModal($event, me.id)"
            @rangeSelected="openModalWithRange($event, me.id)"
            @bookingClicked="openModalWithBooking($event, me.id)"
          />
        </div>
        <div
          v-for="(person, index) in users"
          :key="index"
          class="person"
        >
          <PersonInfo
            :person="person"
          />
          <HorizontalMonthCalendar
            :startingDate="startingDate"
            :showHeader="index==0"
            :booked-days="getBookingsByUserId(person.id)"
            :disable-clicks="isCurrentUserAuthorized"
            @dayClicked="openModal($event, person.id)"
            @rangeSelected="openModalWithRange($event, person.id)"
            @bookingClicked="openModalWithBooking($event, person.id)"
          />
        </div>
      </div>
    </div>
    <div v-else class="loader-container">
      <i class="icon loader black" />
    </div>
  </div>
</template>

<script setup>
import HorizontalMonthCalendar from '@/components/HorizontalMonthCalendar.vue';
import DashboardHeader from '@/components/DashboardHeader.vue';
import BookTimeOffModal from '@/components/BookTimeOffModal.vue';
import { apiService } from '@/composables/useApiService'
import { useUserStore } from '@/stores/user';
import { useBookingsStore } from '@/stores/bookings';
import { useLeaveTypeStore } from '@/stores/leave-types';
import { computed, ref, watch, watchEffect } from 'vue'
import PersonInfo from '@/components/PersonInfo.vue';

const isLoading = ref(false);

const userStore = useUserStore();
const bookingsStore = useBookingsStore();
const leaveTypeStore = useLeaveTypeStore();

const dateProp = ref(null);
const modalStartDate = ref(new Date());
const modalStartTime = ref(0);
const modalEndDate = ref(null);
const modalEndTime = ref(1);
const showModal = ref(false);

const modalId = ref(null);
const modalBooking = ref(null);

const bookings = ref([]);

const getBookingsByUserId = (userId) => {
  return bookingsStore.getBookingsByUserId(userId);
}

const startingDate = computed(() => {
  if (dateProp.value) {
    return new Date(dateProp.value.year, dateProp.value.month, 1);
  }
  return null;
});

const openModal = (data, id) => {
  modalBooking.value = null;
  if (data) {
    modalStartDate.value = new Date(data.year, data.month, data.day);
    modalStartTime.value = data.time;
    modalId.value = id;
    showModal.value = true;
    return
  }
  modalId.value = me.value.id;
  showModal.value = true
};

const openModalWithRange = (data, id) => {
  modalBooking.value = null;
  modalStartDate.value = data.startDate;
  modalStartTime.value = data.startTime;
  modalEndDate.value = data.endDate;
  modalEndTime.value = data.endTime;
  modalId.value = id;
  showModal.value = true;
}

const openModalWithBooking = (data, id) => {
  modalId.value = id;
  modalBooking.value = data;
  showModal.value = true;
}

const closeModal = () => {
  showModal.value = false;
  modalStartDate.value = new Date();
  modalStartTime.value = 0;
  modalEndDate.value = null;
  modalEndTime.value = 1;
  modalId.value = null;
  modalBooking.value = null;
};

const me = computed(() => {
  if (userStore.theAccount) {
    return userStore.theAccount;
  }
  return null;
});

const users = computed(() => {
  return userStore.getUsers.filter((user) => {
    return me.value ? user.id !== me.value.id : true;
  });
});

const isCurrentUserAuthorized = computed(() => {
  if (userStore.theAccount) {
    return userStore.theAccount.role === 'admin' || userStore.theAccount.role === 'owner';
  }
  return false;
});

watchEffect(() => {
  if (userStore.theAccount) {
    bookings.value = bookingsStore.getBookingsByUserId(userStore.theAccount.id);
  }
});

if (bookings.value.length === 0 || users.value.length === 0) {
  isLoading.value = true;
  apiService.views.getDashboard().then((response) => {
    bookingsStore.setBookings(response.payload.bookings);
    isLoading.value = false;
  });
}

apiService.leaveTypes.getLeaveTypes()
  .then(resp => {
    if (typeof resp == 'undefined') {
      return
    }
    leaveTypeStore.setLeaveTypes(resp.payload)
  })
</script>
