<template>
  <page-header v-if="showHeader" />
  <router-view />
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import { useUserStore } from '@/stores/user';
import { apiService } from '@/composables/useApiService'
import { useHolidayStore } from '@/stores/holidays';

export default {
  components: { PageHeader },

  computed: {
    showHeader() {
      return !this.$route.path.includes('/auth')
    },
  },

  async created() {
    try {
      const userStore = useUserStore()
      const holidayStore = useHolidayStore()

      await userStore.fetchMe(true).then((response) => {
        if (response.payload) {
          apiService.user.getUsers().then((response) => {
            userStore.setUsers(response.payload);
          });

          apiService.holidays.getHolidays().then(resp => {
            holidayStore.setHolidays(resp.payload)
          })
        }
      });
      userStore.init()

    } catch (e) {
      console.error('[App] Failed to fetch user', e)
    }
  }
};
</script>
