const namespace = 'leave-type'

export default ($request) => ({
  getLeaveTypes() {
    return $request.get(`${namespace}s`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  getLeaveTypeById(id) {
    return $request.get(`${namespace}/${id}`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  postLeaveType(name, machine_name) {
    const data = {
      "name": name,
      "machine_name": machine_name
    }

    return $request.post(`${namespace}`, data)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  putLeaveType(id, name, machine_name) {
    const data = {
      "name": name,
      "machine_name": machine_name
    }

    return $request.put(`${namespace}/${id}`, data)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  deleteLeaveType(id) {
    return $request.delete(`${namespace}/${id}`)
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  }
})
