<template>
    <div class="view my-calendar-view">
      <div v-if="!isLoading" class="container">
        <CalendarHeader
          v-model="currentYear"
          :name="name"
          @open="openModal()"
        />
        <BookTimeOffModal
          v-if="showModal"
          :startDate="modalStartDate"
          :startTime="modalStartTime"
          :endDate="modalEndDate"
          :endTime="modalEndTime"
          :existingBooking="modalBooking"
          @close="closeModal()"
        />
        <div class="main-content">
          <YearCalendar
            :year="currentYear"
            :booked-days="bookingsByYear"
            @dayClicked="openModal"
            @rangeSelected="openModalWithRange"
            @bookingClicked="openModalWithBooking"
          />
          <div class="calendar-statistics">
            <div class="card yearly-information">
              <div class="card-header">
                <div class="title">{{ currentYear }}. évi információk</div>
              </div>
              <div class="days">Napok</div>

              <div class="allowance">
                <div>Szerződéses juttatás</div>
                <div>{{ allowance }}</div>
              </div>
              <div class="lieu">
                <div>További juttatás</div>
                <div>-</div>
              </div>
              <div class="divider" />

              <div class="total">
                <div>Összesen</div>
                <div>{{ allowance }}</div>
              </div>
              <div class="used">
              </div>
              <div class="divider" />

              <div class="remaining">
                <div>Megmaradt napok</div>
                <div class="bigger">{{ allowanceLeft }}</div>
              </div>

            </div>
            <div class="card non-deductible">
              <div class="card-header">
                <div class="title">Le nem vonható szabadság</div>
              </div>
              <div class="public-holidays">
                <i class="icon calendar"></i>
                <div class="title">Munkaszüneti napok</div>
                <div class="days">{{ numberOfHolidays }}</div>
              </div>
            </div>
            <div class="card time-off">
              <div class="card-header">
                <div class="title">Szabadságok</div>
              </div>
              <TimeOffDiagram :time-off="leavesChart"/>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="loader-container">
        <i class="icon loader black" />
      </div>
    </div>
</template>

<script setup>
import CalendarHeader from '@/components/CalendarHeader.vue';
import BookTimeOffModal from '@/components/BookTimeOffModal.vue';
import YearCalendar from '@/components/YearCalendar.vue';
import TimeOffDiagram from '@/components/TimeOffDiagram.vue';
import { apiService } from '@/composables/useApiService'
import { ref, computed, onMounted, watch } from 'vue';
import { useUserStore } from '@/stores/user';
import { useHolidayStore } from '@/stores/holidays';

const holidayStore = useHolidayStore();
const userStore = useUserStore();

const allowance = ref(null);
const allowanceLeft = ref(null);
const leavesChart = ref([]);
const bookings = ref([]);
const bookingsByYear = ref([]);
const currentYear = ref(new Date().getFullYear());
const showModal = ref(false);

const modalStartDate = ref(new Date());
const modalStartTime = ref(0);
const modalEndDate = ref(null);
const modalEndTime = ref(1);
const modalBooking = ref(null);

const isLoading = ref(false);

const numberOfHolidays = computed(() => {
  return holidayStore.getHolidayLengthByYear(currentYear.value);
});
const user = computed(()=>userStore.theAccount)

const name = computed(() => {
  if (user.value) {
    return user.value.first_name + ' ' + user.value.last_name;
  }
  return '';
});

const openModal = (data) => {
  modalBooking.value = null;
  if (data) {
    modalStartDate.value = new Date(data.year, data.month, data.day);
    modalStartTime.value = data.time;
    modalEndDate.value = null;
    modalEndTime.value = 1;
  }
  showModal.value = true;
};

const openModalWithRange = (data) => {
  modalStartDate.value = data.startDate;
  modalStartTime.value = data.startTime;
  modalEndDate.value = data.endDate;
  modalEndTime.value = data.endTime;
  showModal.value = true;
}

const openModalWithBooking = (data) => {
  modalBooking.value = data;
  showModal.value = true;
}

const closeModal = () => {
  showModal.value = false;
};

const getBookingsByYear = () => {
  isLoading.value = true;
  apiService.bookings.getBookingsByYear(currentYear.value).then((response) => {
    bookingsByYear.value = response.payload || [];
    isLoading.value = false;
  });
};
const getMyCalendarData = () => {
  isLoading.value = true;
  apiService.views.getMyCalendar().then((response) => {
    allowance.value = response.payload.allowance;
    allowanceLeft.value = response.payload.allowance_left;
    leavesChart.value = response.payload.leaves;
    bookings.value = response.payload.bookings;
    isLoading.value = false;
  });
};

watch(currentYear, () => {
  getBookingsByYear();
});

// no need for lifecycle hooks
getBookingsByYear();
getMyCalendarData();
//------------------
</script>
