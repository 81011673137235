<template>
  <div class="page-header">
    <div class="container">

      <div class="left">
        <router-link to="/" class="logo"></router-link>
        <router-link to="/" class="company">{{ companyName }}</router-link>
      </div>

      <div class="right">
        <div class="navigation">
          <router-link to="/working-hours">Munkaidő</router-link>
          <router-link to="/absences">Szabadságok</router-link>
          <router-link to="/my-calendar">Naptár</router-link>
        </div>
        <MenuDropdown :name="name" diameter="30"></MenuDropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import MenuDropdown from './MenuDropdown.vue';
import { computed } from 'vue';
import { useUserStore } from '@/stores/user';
const userStore = useUserStore();

const name = computed(()=>{
  if (userStore.theAccount) {
    return userStore.theAccount.last_name + ' ' + userStore.theAccount.first_name
  }
  return '';
})

const companyName = computed(()=>{
  if (userStore.theAccount) {
    return userStore.theAccount.company.name;
  }
  return '';
})
</script>
