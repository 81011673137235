import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import('./assets/scss/styles.scss');

import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)

app.use(Vue3Toasity,
{
  autoClose: 6000,
})

app.use(router)
app.mount('#app')
