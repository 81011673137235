<template>
  <div class="menu-dropdown" ref="menu">
    <AccountIcon :name="name" diameter="30" @click="toggleMenu"/>

    <div
      v-if="isOpen"
      class="menu"
    >
      <router-link
        to="/my-account"
        class="menu-item"
      >
        Fiókom
      </router-link>
      <router-link
        v-if="isUserAuthorized"
        class="menu-item"
        to="/holidays"
      >
        Ünnepnapok
      </router-link>
      <router-link
        v-if="isUserAuthorized"
        class="menu-item"
        to="/users"
      >
        Felhasználók
      </router-link>
      <router-link
        v-if="isUserAuthorized"
        class="menu-item"
        to="/company"
      >
        Cég kezelése
      </router-link>
      <div
        class="menu-item"
        @click="logout"
      >
        Kilépés
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import AccountIcon from './AccountIcon.vue';
import { useUserStore } from '@/stores/user';
import { useMenuStore } from '@/stores/menu';
import { isUserAuthorized } from '@/utils/user';

const menuStore = useMenuStore();
const isOpen = computed(() => menuStore.isOpened);



const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const toggleMenu = () => {
  menuStore.setIsOpened(!isOpen.value);
};

const closeMenuOnClickOutside = (event) => {
  if (isOpen.value && menu.value && !menu.value.contains(event.target)) {
    menuStore.setIsOpened(false);
  }
};

const menu = ref(null);

const logout = () => {
  useUserStore().logout()

  window.location = '/'
}

onMounted(() => {
  document.addEventListener('click', closeMenuOnClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', closeMenuOnClickOutside);
});

</script>
