
<template>
  <div class="view dashboard-view working-hours-view">
    <div v-if="!isLoading" class="container">
      <DashboardHeader
        v-model="dateProp"
        @open="openModal()"
      />
      <WorkingHoursModal
        v-if="showModal"
        :startDate="modalStartDate"
        :startTime="modalStartTime"
        :endDate="modalEndDate"
        :endTime="modalEndTime"
        :userId="modalId"
        :existingWorkingHour="modalWorkingHour"
        @close="closeModal()"
      />

      <div class="persons-container">
        <div
          v-if="me"
          class="person me"
        >
          <PersonInfo
            :person="me"
          />
          <HorizontalMonthCalendar
            :startingDate="startingDate"
            :showHeader="true"
            :workingHours="myWorkingHours"
            @dayClicked="openModal($event, me.id)"
            @rangeSelected="openModalWithRange($event, me.id)"
            @workingHourClicked="openModalWithWorkingHour"
          />
        </div>
        <div
          v-for="(person, index) in users"
          :key="index"
          class="person"
        >
          <PersonInfo
            :person="person"
          />
          <HorizontalMonthCalendar
            :startingDate="startingDate"
            :showHeader="index==0"
            :workingHours="coworkersWorkingHoursByUser(person.id)"
            :disable-clicks="isCurrentUserAuthorized"
            @dayClicked="openModal($event, person.id)"
            @rangeSelected="openModalWithRange($event, person.id)"
            @workingHourClicked="openModalWithWorkingHour($event, person.id)"
          />
        </div>
      </div>
    </div>
    <div v-else class="loader-container">
      <i class="icon loader black" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect, watch, onMounted } from 'vue';
import { apiService } from '@/composables/useApiService';
import DashboardHeader from '@/components/DashboardHeader.vue';
import WorkingHoursModal from '@/components/WorkingHoursModal.vue';
import HorizontalMonthCalendar from '@/components/HorizontalMonthCalendar.vue';
import PersonInfo from '@/components/PersonInfo.vue';
import { useWorkingHoursStore } from '@/stores/working-hours';
import { useUserStore } from '@/stores/user';

const workingHoursStore = useWorkingHoursStore();
const userStore = useUserStore();

const emit = defineEmits(['open', 'update:modelValue']);

const showModal = ref(false);
const isLoading = ref(false);

const modalStartDate = ref(new Date());
const modalStartTime = ref(0);
const modalEndDate = ref(null);
const modalEndTime = ref(1);

const modalId = ref(null);
const modalWorkingHour = ref(null);

const dateProp = ref(null);

const myWorkingHours = ref([]);

const loggedInUser = computed(() => {
  return userStore.theAccount;
});

const startingDate = computed(() => {
  if (dateProp.value) {
    return new Date(dateProp.value.year, dateProp.value.month, 1);
  }
  return null;
});

const isCurrentUserAuthorized = computed(() => {
  if (userStore.theAccount) {
    return userStore.theAccount.role === 'admin' || userStore.theAccount.role === 'owner';
  }
  return false;
});

const coworkersWorkingHoursByUser = (userid) => {
  return workingHoursStore.getWorkingHoursByUserId(userid);
};

const users = computed(() => {
  return userStore.getUsers.filter((user) => {
    return user.id !== loggedInUser.value.id;
  });
});

const openModal = (data, id) => {
  modalWorkingHour.value = null;
  if (data) {
    modalStartDate.value = new Date(data.year, data.month, data.day);
    modalStartTime.value = data.time;
    modalId.value = id;
  }
  showModal.value = true;
};

const openModalWithWorkingHour = (data, id) => {
  modalWorkingHour.value = data;
  showModal.value = true;
}

const openModalWithRange = (data, id) => {
  modalStartDate.value = data.startDate;
  modalStartTime.value = data.startTime;
  modalEndDate.value = data.endDate;
  modalEndTime.value = data.endTime;
  modalId.value = id;
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
  modalStartDate.value = new Date();
  modalStartTime.value = 0;
  modalEndDate.value = null;
  modalEndTime.value = 1;
  myWorkingHours.value = workingHoursStore.getWorkingHoursByUserId(userStore.theAccount.id);
};

const me = computed(() => {
  if (userStore.theAccount) {
    return userStore.theAccount;
  }
  return null;
});

watchEffect(() => {
  if (userStore.theAccount) {
    myWorkingHours.value = workingHoursStore.getWorkingHoursByUserId(userStore.theAccount.id);
  }
});


if (myWorkingHours.value.length === 0) {
  isLoading.value = true;
  apiService.workingHours.getWorkingHours().then((workingHours) => {
    workingHoursStore.setWorkingHours(workingHours.payload);
    isLoading.value = false;
  });
}
</script>
