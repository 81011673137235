<template>
  <div
    class="account-icon"
    :style="iconStyles"
  >
    {{ initials }}
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  diameter: {
    type: String,
    required: true,
  },
});

const initials = computed(() => {
  let splitted = props.name.split(' ');
  if (splitted.length < 2) {
    return splitted[0][0];
  }
  return splitted[0][0] + splitted[1][0];
});

const iconStyles = computed(() => ({
  width: props.diameter + 'px',
  height: props.diameter + 'px',
  lineHeight: props.diameter + 'px',
  fontSize: props.diameter * 0.5 + 'px',
  minWidth: props.diameter + 'px',
  minHeight: props.diameter + 'px',
}));

</script>
