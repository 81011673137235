<template>
  <div class="modal user-form-modal">
    <div class="modal-mask">
      <div
        class="modal-container"
        ref="modalContainer"
      >
        <div class="modal-header">
          {{ title }}
          <i
            class="icon close"
            @click="closeModal"
          />
        </div>

        <div class="modal body">
          <UserForm
            :isLoading="isLoading"
            :user="user"
            @save="send"
            @cancel="closeModal"
          />
        </div>
    </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { toast } from 'vue3-toastify';
import { apiService } from '@/composables/useApiService';
import { useUserStore } from '@/stores/user';
import UserForm from '@/components/UserForm.vue';

const props = defineProps({
  user: {
    type: Object,
    default: null,
  },
})

const userStore = useUserStore();
const emit = defineEmits(['close'])
const closeModal = () => {
  emit('close')
}

const title = computed(() => {
  if (props.user) {
    return 'Felhasználó szerkesztése'
  }
  else {
    return 'Felhasználó létrehozása'
  }
})

const isLoading = ref(false)

const send = (data) => {
  data.company_id = userStore.theAccount.company_id
  apiService.user.postUser(data)
    .then(resp => {
      console.log(resp);
      isLoading.value = false

      if (resp.code == 200) {
        toast.success('Felhasználó sikeresen létrehozva')
        closeModal()
      }
      else {
        toast.error('Hiba történt a felhasználó létrehozása közben')
        return
      }
    })
    .catch(e => {
      console.log(e)
      return
    })
}
</script>
