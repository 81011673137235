<template>
  <div class="time-off-diagram">
    <div
      v-for="(month, index) in months"
      :key="index"
      class="month"
    >
      <div
        :style="computeBarHeight(index)"
        class="bar"
      />
      <div class="month-name">{{ month }}</div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  timeOff: {
    type: Array,
    required: true,
  },
});

const months = ['J', 'F', 'M', 'Á', 'M', 'J', 'J', 'A', 'Sz', 'O', 'N', 'D']

const computeBarHeight = (index) => {
  const daysOff = props.timeOff[index];
  return `height: ${daysOff * 2}px`;
};
</script>
