<template>
  <div
    name="book-time-off-modal"
    class="modal book-time-off-modal"
  >
    <div
      class="modal-mask"
    >
      <div
        class="modal-container"
        ref="modalContainer"
      >

        <div class="modal-header">
          {{ title }}
          <i
            class="icon close"
            @click="closeModal"
          />
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="select-group">
              <div class="title">Típus</div>
              <DropdownSelect
                v-model="selectedType"
                class="type"
                :options="leaveTypes"
                :default-selected-option="leaveTypes[0]"
                label="name"
              />
            </div>
          </div>

          <div class="row">
            <div class="select-group">
              <div class="title">Kezdődátum</div>
              <div class="selects">
                <div class="day-picker">
                  <Datepicker
                    v-model="selectedStartDate"
                    :locale="hu"
                  />
                </div>

                <DropdownSelect
                  v-model="selectedStartTime"
                  class="time"
                  :options="availableStartTimeOptions"
                  :default-selected-option="selectedStartTime"
                />
              </div>
            </div>
            <div class="select-group">
              <div class="title">Végdátum</div>
              <div class="selects">
                <div class="day-picker">
                  <Datepicker
                    v-model="selectedEndDate"
                    :lowerLimit="selectedStartDate"
                    :locale="hu"
                  />
                </div>
                <DropdownSelect
                  v-model="selectedEndTime"
                  class="time"
                  :options="availableEndTimeOptions"
                  :default-selected-option="selectedEndTime"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="select-group">
              <div class="title">Indoklás</div>
              <textarea
                v-model="reason"
                type="text"
                class="reason"
                placeholder="Írd le az indoklásod"
              />
            </div>
          </div>
        </div>
        <div
          v-if="!existingBooking"
          class="modal-footer"
        >
          <button
            v-if="canModify"
            class="button"
            :disabled="isLoading"
            @click="send"
          >
            <template v-if="!isLoading">
              <i class="icon tick"/>
              Foglalás elküldése
            </template>
            <template v-else>
              <i class="icon icon loader white"/>
            </template>

          </button>
          <div
            v-else
            class="text"
          >
            Már nem módosítható a foglalás
          </div>
          <div class="days-off">{{ daysOff }} napot vesz el a szabadságból</div>
        </div>
        <div
          v-else
          class="modal-footer"
        >
          <button class="button" @click="modifyBooking">
            <template v-if="!isLoading">Foglalás módosítása</template>
            <template v-else><i class="icon icon loader white"/></template>
          </button>
          <button class="button" @click="deleteBooking">
            <template v-if="!isLoading">Foglalás törlése</template>
            <template v-else><i class="icon loader white"/></template>
          </button>
          <div class="days-off">{{ daysOff }} napot vesz el a szabadságból</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DropdownSelect from './DropdownSelect.vue';
import Datepicker from 'vue3-datepicker'
import { toast } from 'vue3-toastify';
import { apiService } from '@/composables/useApiService';
import { useBookingsStore } from '@/stores/bookings';
import { useLeaveTypeStore } from '@/stores/leave-types';
import { useHolidayStore } from '@/stores/holidays';
import {ref, onMounted, watch, computed } from 'vue';
import { hu } from 'date-fns/locale';

const leaveTypeStore = useLeaveTypeStore();
const bookingsStore = useBookingsStore();
const holidaysStore = useHolidayStore();


const emit = defineEmits(['close']);

const holidayDates = holidaysStore.getHolidayDates;

const leaveTypes = ref(leaveTypeStore.getLeaveTypes);
const selectedType = ref(leaveTypeStore.getLeaveTypes[0]);
const startTimeOptions = ['Reggel', 'Ebédidő'];
const endTimeOptions = ['Ebédidő', 'Este'];


const reason = ref('');
const daysOff = ref(0);

const modalContainer = ref(null);
const props = defineProps({
  startDate: {
    type: Date,
    default: new Date(),
  },
  endDate: {
    type: Date,
    default: new Date(),
  },
  startTime: {
    type: Number,
    default: 0,
  },
  endTime: {
    type: Number,
    default: 1,
  },
  userId: {
    type: String,
    default: null,
  },
  existingBooking: {
    type: Object,
    default: null,
  },
});

const selectedStartDate = ref(props.startDate);
const selectedEndDate = ref(props.endDate ? props.endDate: props.startDate);
const selectedStartTime = ref(startTimeOptions[props.startTime]);
const selectedEndTime = ref(endTimeOptions[props.endTime]);

const isLoading = ref(false);

const title = computed(() => {
  if (props.existingBooking) {
    return 'Foglalás módosítása';
  }
  return 'Munkaidő foglalása';
});

const availableStartTimeOptions = computed(() => {
  if (selectedEndTime.value == endTimeOptions[0] && selectedStartDate.value == selectedEndDate.value) {
    return [startTimeOptions[0]];
  } else {
    return startTimeOptions;
  }
});
const availableEndTimeOptions = computed(() => {
  if (selectedStartTime.value == startTimeOptions[1] && selectedStartDate.value == selectedEndDate.value) {
    return [endTimeOptions[1]];
  } else {
    return endTimeOptions;
  }
});

const isNotWorkingDay = (date) => {
  const dayOfWeek = date.getDay();
  const isWeekend = (dayOfWeek == 6) || (dayOfWeek == 0);
  const isHoliday = isDateHoliday(date);
  return isWeekend || isHoliday;
}
const isDateHoliday = (date) => {
  const isHoliday = holidayDates.some((holidayDate) => {
    const holiday = new Date(holidayDate);
    return holiday.getFullYear() == date.getFullYear() && holiday.getMonth() == date.getMonth() && holiday.getDate() == date.getDate();
  });
  return isHoliday;
}
const isOneEndNWD = () => {
  return isNotWorkingDay(selectedStartDate.value) || isNotWorkingDay(selectedEndDate.value);
}


var calculateBusinessDays = () => {
  var startDate = new Date(selectedStartDate.value);
  var endDate = new Date(selectedEndDate.value);
  var count = 0;

  if (startDate == endDate) {
    count = 1;
  } else{
    for (var curDate = startDate; curDate <= endDate; curDate.setDate(curDate.getDate() + 1)) {
      if (!isNotWorkingDay(curDate)) {
        count++;
      }
    }
  }
  if (selectedStartTime.value == startTimeOptions[0] && selectedEndTime.value == endTimeOptions[0]) {
    count -= 0.5;
  }
  else if (selectedStartTime.value == startTimeOptions[1] && selectedEndTime.value == endTimeOptions[1]) {
    count -= 0.5;
  }
  else if (selectedStartDate.value !== selectedEndDate.value && selectedStartTime.value == startTimeOptions[0] && selectedEndTime.value == endTimeOptions[0]){
    count += 0.5;
  }
  else if (selectedStartDate.value !== selectedEndDate.value && selectedStartTime.value == startTimeOptions[1] && selectedEndTime.value == endTimeOptions[1]){
    count += 0.5;
  }
  if (isOneEndNWD() && selectedStartTime.value === startTimeOptions[1]) {
    count += 0.5;
  }
  else if (isOneEndNWD() && selectedEndTime.value === endTimeOptions[0]) {
    count += 0.5;
  }

  daysOff.value = count;
}

watch(() => selectedStartTime.value, (newStartTime) => {
  calculateBusinessDays();
});
watch(() => selectedEndTime.value, (newEndTime) => {
  calculateBusinessDays();
});
watch(() => selectedStartDate.value, (newStartDate) => {
  if (selectedEndDate.value < newStartDate){
    selectedEndDate.value = newStartDate;
  }
  calculateBusinessDays();
});
watch(() => selectedEndDate.value, (newEndDate) => {
  calculateBusinessDays();
});

const getDayPartFromDate = (date, time) => {
  const hours = date.getHours();

  if (hours < 12 && time ==="start") {
    return startTimeOptions[0];
  }
  else if (hours === 12 && time ==="end") {
    return endTimeOptions[0];
  }
  else if (hours === 12 && time ==="start") {
    return startTimeOptions[1];
  }
  else if (hours > 12 && time ==="end") {
    return endTimeOptions[1];
  }

};

const isAlreadyBooked = () => {
  let bookings = bookingsStore.getBookingsByUserId(props.userId);
  const bookingsInTimeFormat = bookings.map((booking) => {
    const startDate = new Date(booking.start_date).getTime();
    const endDate = new Date(booking.end_date).getTime();
    return {
      startDate,
      endDate,
    };
  });

  if (bookingsInTimeFormat.some((booking) => {
    const selectedStartDateInTimeFormat = selectedStartDate.value.getTime();
    const selectedEndDateInTimeFormat = selectedEndDate.value.getTime();

    // Check if selected start date is within any booking's date range
    const startDateOverlap = (
      selectedStartDateInTimeFormat >= booking.startDate &&
      selectedStartDateInTimeFormat <= booking.endDate
    );

    // Check if selected end date is within any booking's date range
    const endDateOverlap = (
      selectedEndDateInTimeFormat >= booking.startDate &&
      selectedEndDateInTimeFormat <= booking.endDate
    );

    // Check if the entire selected range is within any booking's date range
    const rangeOverlap = (
      selectedStartDateInTimeFormat <= booking.startDate &&
      selectedEndDateInTimeFormat >= booking.endDate
    );

    return startDateOverlap || endDateOverlap || rangeOverlap;
  })) {
    toast.error('Ez az időpont már foglalt!');
    return true;
  }
};

const closeModal = () => {
  emit('close');
};


const send = () => {
  isLoading.value = true;
  if (isAlreadyBooked()){
    isLoading.value = false;
    return;
  };
  if (selectedType.value === null) {
    isLoading.value = false;
    toast.error('Válassz típust!');
    return;
  }

  const start = new Date(selectedStartDate.value);
  const end = new Date(selectedEndDate.value);

  if (startTimeOptions.indexOf(selectedStartTime.value) === 0) {
    start.setHours(0, 0, 0);
  }else{
    start.setHours(12, 0, 0);
  }
  if (endTimeOptions.indexOf(selectedEndTime.value) === 0) {
    end.setHours(12, 0, 0);
  }else{
    end.setHours(23, 59, 59);
  }


  apiService.bookings.postBooking(reason.value, start, end, selectedType.value.machine_name, props.userId).then((resp) => {
    bookingsStore.addBooking(resp.payload, props.userId);
    closeModal();
    toast.success('Sikeres foglalás!');
  }).catch((error) => {
    console.log(error);
    toast.error('Sikertelen foglalás!');
  }).finally(() => {
    isLoading.value = false;
  })
};

const modifyBooking = () => {
  isLoading.value = true;
  const start = new Date(selectedStartDate.value);
  const end = new Date(selectedEndDate.value);

  if (startTimeOptions.indexOf(selectedStartTime.value) === 0) {
    start.setHours(0, 0, 0);
  }else{
    start.setHours(12, 0, 0);
  }
  if (endTimeOptions.indexOf(selectedEndTime.value) === 0) {
    end.setHours(12, 0, 0);
  }else{
    end.setHours(23, 59, 59);
  }

  apiService.bookings.putBooking(props.existingBooking.id, reason.value, start, end, selectedType.value.machine_name, props.userId).then((resp) => {
    bookingsStore.modifyBooking(resp.payload, props.userId);
    closeModal();
    toast.success('Sikeres módosítás!');
  }).catch((error) => {
    console.log(error);
    toast.error('Sikertelen módosítás!');
  }).finally(() => {
    isLoading.value = false;
  })
}

const deleteBooking = () => {
  isLoading.value = true;
  apiService.bookings.deleteBooking(props.existingBooking.id).then(() => {
    bookingsStore.deleteBooking(props.existingBooking.id, props.userId);
    closeModal();
    toast.success('Sikeres törlés!');
  }).catch((error) => {
    console.log(error);
    toast.error('Sikertelen törlés!');
  }).finally(() => {
    isLoading.value = false;
  })
}

const canModify = computed(() => {
  if (props.existingBooking) {
    const startDate = new Date(props.existingBooking.start_date);
    const endDate = new Date(props.existingBooking.end_date);
    const now = new Date();
    return startDate > now && endDate > now;
  }
  return true;
});



onMounted(() => {
  calculateBusinessDays();
  if (props.existingBooking) {
    selectedType.value = leaveTypes.value.find((leaveType) => leaveType.machine_name === props.existingBooking.leave_type.machine_name);
    selectedStartDate.value = new Date(props.existingBooking.start_date);
    selectedEndDate.value = new Date(props.existingBooking.end_date);
    selectedStartTime.value = getDayPartFromDate(new Date(props.existingBooking.start_date), "start");
    selectedEndTime.value = getDayPartFromDate(new Date(props.existingBooking.end_date), "end");
    reason.value = props.existingBooking.reason ? props.existingBooking.reason : '';
  }
});
</script>
