<template>
  <div class="dashboard-header">
     <MonthSelector v-model="currentYearWithMonth"
    />

    <button
      class="button modal-animated-button"
      @click="emit('open')"
    >
      <i class="icon plus white" />
      <i class="icon calendar white" />
    </button>
  </div>
</template>

<script setup>
import MonthSelector from './MonthSelector.vue';
import {watch, ref } from 'vue';

const emit = defineEmits(['open', 'update:modelValue']);

const currentYearWithMonth = ref(null)


watch(currentYearWithMonth, (newVal) => {
  emit('update:modelValue', newVal);
})
</script>
