<template>
  <div class="auth-page new-password">
    <i class="icon logo" />
    <div class="page-title">Új jelszó</div>

    <div class="input-group">
      <input
        v-model="email"
        :class="{ error: emailError }"
        type="email"
        placeholder="Email cím"
        @input="emailError = ''"
      />
      <div
        class="error-message"
        v-if="emailError"
      >
        {{ emailError }}
      </div>
    </div>

    <div class="input-group">
      <input
        v-model="password"
        :class="{ error: passwordError }"
        type="password"
        placeholder="Új jelszó"
        @input="passwordError = ''"
      />
      <div
        class="error-message"
        v-if="passwordError"
      >
        {{ passwordError }}
      </div>
    </div>

    <div class="input-group">
      <input
        v-model="passwordConfirmation"
        :class="{ error: passwordConfirmationError }"
        type="password"
        placeholder="Új jelszó megerősítése"
        @input="passwordConfirmationError = ''"
      />
      <div
        class="error-message"
        v-if="passwordConfirmationError"
      >
        {{ passwordConfirmationError }}
      </div>
    </div>

    <button class="button primary" :disabled="isLoading" @click="submit()">
      <i
        class="icon loader white"
        v-if="isLoading"
        :loading="isLoading"
        color="#ffffff"
        size="6px"
      />
      <template v-else>Jelszó beállítása</template>
    </button>

    <div class="secondary-links">
      <router-link to="/auth/login">Vissza a bejelentkezéshez</router-link>
    </div>
  </div>
</template>

<script setup>
import { isValidPassword, isValidEmail } from '@/utils/validators';
import { ref } from 'vue';

const email = ref('');
const password = ref('');
const passwordConfirmation = ref('');
const isLoading = ref(false);
const emailError = ref('');

const passwordError = ref('');
const passwordConfirmationError = ref('');


const submit = () => {
  if (!validate()) {
    return;
  }

  isLoading.value = true;

};

const validate = () => {
  emailError.value = '';
  passwordError.value = '';
  passwordConfirmationError.value = '';

  if (!email.value) {
    emailError.value = 'Email megadása kötelező.';
    return false;
  }

  if (!isValidEmail(email.value)) {
    emailError.value = 'Email cím formátuma nem megfelelő.';
    return false;
  }

  if (!isValidPassword(password.value)) {
    passwordError.value = 'A jelszónak legalább 8 karakter hosszúnak kell lennie.';
    return false;
  }

  if (password.value !== passwordConfirmation.value) {
    passwordConfirmationError.value = 'A két jelszó nem egyezik meg.';
    return false;
  }

  return true;
};

</script>
