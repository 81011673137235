<template>
  <div class="calendar-header">
    <div class="name-group">
      <AccountIcon
        :name="name"
        diameter="50"
      />
      <div class="name">{{ name }}</div>
    </div>

    <YearSelector v-model="currentYear"/>
    <button
      class="button modal-animated-button"
      @click="emit('open')"
    >
      <i class="icon plus white" />
      <i class="icon calendar white" />
    </button>
  </div>
</template>

<script setup>
import AccountIcon from './AccountIcon.vue';
import YearSelector from './YearSelector.vue';

import {watch, ref } from 'vue';

const emit = defineEmits(['open', 'update:modelValue']);
const currentYear = ref(null)
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

watch(currentYear, (newVal) => {
  emit('update:modelValue', newVal);
})
</script>
