
export default ($request) => ({
  getDashboard() {
    return $request.get('dashboard')
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  },

  getMyCalendar() {
    return $request.get('my-calendar')
      .then(resp => {
        if (typeof resp == 'undefined') {
          return
        }
        return resp
      })
  }

})
