<template>
  <div class="person-info">
    <AccountIcon
      :name="personName"
      diameter="50"
    />
    <div class="textual">
      <div class="name">{{ personName }}</div>
      <div v-if="person.email" class="email">{{ person.email }}</div>
    </div>

  </div>
</template>

<script setup>
import { computed } from 'vue';
import AccountIcon from '@/components/AccountIcon.vue';
const props = defineProps({
  person: {
    type: Object,
    required: true
  }
})

const personName = computed(() => {
  return props.person.name ? props.person.name : `${props.person.last_name} ${props.person.first_name}`
})
</script>
